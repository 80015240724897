export default {
	'upcomingAppointments': 'próximas citas',
	'addAppointments': 'Agregar citas',
	'addPatient': 'Agregar Dependiente',
	'welcomeBack': 'Bienvenido',
	'noAppointments': 'Sin citas',
	'appointment': 'cita',
	'allRightsReserverd': 'Reservados todos los derechos',
	'privacyPolicy': 'Política de privacidad',
	'terms&&conditions': 'Términos y condiciones',
	'helpMessage': 'Si se trata de una emergencia, llame al 911. Si está pensando en hacerse daño a sí mismo o a otros y/o está pasando por una crisis, llame a la línea de crisis disponible las 24 horas del día al 1-800-534-4673',
	'invoices': 'facturas',
	'appointments': 'equipo',
	'patients': 'paciente',
	'messages': 'mensajes',
	'myTasks': 'mis tareas',
	'bills': 'facturas',
	'myRecords': 'mis registros',
	'myLibrary': 'mi biblioteca',
	'myMembership': 'mi membresía',
	'patientInvoices': 'facturas de pacientes',
	'noInvoices': 'sin facturas',
	'name': 'Nombre',
	'next': 'Siguiente',
	'back': 'Atrás',
	'select': 'Por favor seleccione',
	'selectOnly': 'Seleccionar',
	'explainFurther': 'Amplíe la explicación',
	'explainFurtherPlease': 'Por favor, proporcione más información',
	'formContinueOrStartOver': 'Tiene un formulario incompleto, ¿desea continuar o empezar de nuevo?',
	'startOver': 'REINICIAR',
	'continue': 'CONTINUAR',
	'test': 'works',
	'patientState': 'Estado del Paciente',
	'patientCity': 'Ciudad del Paciente',
	'patientZipcode': 'Código Postal del Paciente',
	'patientStreetAddress': 'Dirección Postal del Paciente',
	'patientFirstName': 'Primer nombre del paciente',
	'patientLastName': 'Apellido del Paciente',
	'undo': 'Deshacer',
	'yes': 'Sí',
	'no': 'No',
	'view': 'Ver',
	'dateTime': 'Fecha y hora',
	'doctor': 'Doctor',
	'paymentStatus': 'Estado de pago',
	'status': 'Estado',
	'reschedule': 'REPROGRAMAR',
	'cancelAppointment': 'CANCELAR CITA',
	'cancelled': 'CANCELADO',
	'fieldRequired': 'El campo anterior es obligatorio',
	'phoneInvalid': 'El teléfono no es válido',
	// welcome-back
	'welcomeBack': 'bienvenido de nuevo!',
	'yourIntakeQues': 'Su cuestionario de admisión para',
	'is': 'es',
	'complete': 'completo',
	'toContinue': 'Para continuar completando su Cuestionario de Admisión para',
	'pleaseClickContinueIntake': 'haga clic en el botón "Continuar ingesta" a continuación.',
	'pleaseHaveAReady': 'Tenga listo cualquiera de los siguientes documentos que apliquen al paciente, se le pedirá que cargue imágenes:',
	'continueIntake': 'Continuar la ingesta',
	'insuranceCards': 'Tarjeta(s) de seguro',
	'courtOrderOrOther': 'Órdenes judiciales o otra documentación legal sobre quien toma las decisiones legales, arreglos de custodia, colocación temporal, adopción, tutela, etc.',
	'schoolDocs': 'Documentos escolares sobre un IEP, plan 504, educación especial, programación para superdotados, etc..',
	'anyPrevEval': 'Cualquier evaluación previa o resultado de pruebas psicológicas.',
	// new-intake
	'thankyouForChoosingAxis': '¡Gracias por elegir Axis for Autism! Esperamos poder apoyarlo durante este importante proceso de prueba.',
	'beginIntakeProcess': 'Para comenzar el proceso de admisión',
	'pleaseClickStartIntake': 'haga clic en el botón "Iniciar ingesta" a continuación. Cuanto antes pueda completar la admisión, más rápido podremos programar su cita y completar el proceso de diagnóstico. El cuestionario puede tardar entre 45 y 60 minutos en completarse. Puedes detenerte en cualquier momento y completar la ingesta más tarde sin perder tu progreso.',
	'startIntake': 'Iniciar la ingesta',
	'contactUsMessage': 'Si tiene alguna pregunta o inquietud, comuníquese con nosotros al (602) 888-8882 o envíenos un correo electrónico a',
	// thank-you
	'thankyouFotIntake': 'Gracias por completar nuestro Cuestionario de admisión.',
	'whatsNext': '¿Que sigue?',
	'careCordinatorWillContact': 'Uno de nuestros coordinadores de atención al paciente se comunicará con usted dentro de los 7 días hábiles para programar una evaluación. La duración de la cita de Evaluación puede durar hasta 2 horas. Por favor planifique en consecuencia',
	'careCordinatorWillDiscuss': 'El Coordinador de Atención al Paciente discutirá con usted cualquier información adicional requerida para la Evaluación durante el proceso de programación.',
	'intakeForAnotherPatient': '¿Necesita completar una admisión para otro paciente?',
	'ifYes': 'En caso afirmativo, seleccione el botón "Iniciar ingesta" a continuación.',

	//ADD-NEW AppointmentType.vue
	'appointmentType': '¿Qué cita te gustaría?',
	'DAE': 'Evaluación de diagnóstico de autismo',
	'PM': 'Psiquiatría/Gestión de medicamentos',
	'consent': `Entiendo que esta información se compartirá con Axis for Autism, LLC. Tengo la autoridad para compartir esta información.`,
	'read': 'Lea y acepte los siguientes términos:',
	'allRequiredFields': 'Todos los campos son obligatorios',

	//FORM-HOME --> /patients/web/src/pages/intake/completingForm/Home.vue
	'who': '¿Quién está llenando este formulario?',
	'optionSelect': 'seleccione su opción',
	'legal': 'Padre legal de hijo/a menor',
	'guardian': 'guardián',
	'adult1': 'Paciente adulta para sí misma',
	'adult2': 'Tutor/POA o persona legalmente autorizada para paciente adulto',
	'other': 'Otro',
	'referal': '¿Cómo se enteró de Axis para el autismo?',
	'evaluationGoals': 'Describa por qué desea que el paciente sea visto en este momento, incluidos sus objetivos y esperanzas para la evaluación',
	'school_Prof_grades_k12': 'Profesional escolar (grados K-12)',
	'preschool': 'Preescolar o guardería',
	'primary_care': 'Médico de atención primaria o especialista',
	'mental_health': 'Proveedor de salud mental (psiquiatra, consejero, psicólogo)',
	'social': 'Medios de comunicación social',
	'friend_family': 'Amigo o familiar',
	'school_name': 'Nombre de escuela',
	'professionals_name': 'Nombre del profesional',
	'preschool_name': 'Nombre del preescolar o guardería',
	'office_name': 'Nombre de la oficina',
	'doctor_name': `El nombre del doctor`,
	'provider_name': `Nombre del proveedor`,
	'facebook': 'Facebook',
	'instagram': 'Instagram',
	'please_specify': 'Por favor especifica',

	// form-demographic ---> Demographic.vue
	'demographicInfo': 'Información demográfica',
	'fullName': 'Nombre completo de la persona que completa este formulario',
	'patientRelation': 'Relación con el paciente',
	'email': 'Email Dirección',
	'phone': 'teléfono',
	'patientDateOfBirth': 'Fecha de Nacimiento del Paciente',
	'prefLang': 'Idioma preferida',
	'pleaseSpecifyLang': 'Por favor especifica el idioma',
	'some_high_school': 'Algún instituto',
	'high_school_graduate_ged': 'Graduado de secundaria / GED',
	'some_college': 'Alguna educación superior',
	'college_graduate': 'Graduado de la Universidad',
	'levelOfEdu': 'Nivel de educación',
	'whoIsEmp': 'Quien es tu empleador',

	//FORM-DEMOGRAPHIC-ADULT --> /patients/web/src/pages/intake/completingForm/Adult.vue
	'adultInfo': 'Paciente adulto siendo visto',
	'nameAdult': 'Nombre, segundo nombre y apellido legal del paciente',
	'nameAdultFirst': 'Nombre legal del paciente',
	'nameAdultMiddle': 'Segundo nombre legal del paciente',
	'nameAdultLast': 'Apellido legal del paciente',
	'preferredName': 'Nombre preferido del paciente si es diferente al anterior',
	'fullAddress': 'Domicilio completo del paciente',
	'streetAddress': 'Dirección',
	'city': 'Ciudad paciente',
	'zip': 'Postal del paciente',
	'state': 'estado del paciente',
	'emailAddress': 'Dirección de correo electrónico del paciente',
	'genderPatient': 'Sexo del paciente asignado al nacer',
	'pronouns': 'Pronombres preferidos por el paciente (si son diferentes a los anteriores)',
	'phonePatient': 'Número de teléfono del paciente',
	'language': 'Idioma principal del paciente hablado / Entendido',
	'otherLanguage': 'Otro(s) idioma(s) hablado(s) / Entendido',
	'withWhom': '¿Con quién vive el paciente?',
	'pa': 'padre',
	'ma': 'madre',
	'alone': 'solo',
	'family': 'familia',
	'friend': 'amigo',
	'otherOption': 'otro',
	'pleaseExplain': "Si respondió 'Otro' a la pregunta anterior, explique más",
	'wh_cw': 'Historia Laboral / Trabajo Actual',
	'mh': 'Historial Militar',
	'zipcodeNotCorrespond': 'El código postal no corresponde a ningún estado/ciudad de EE. UU.',

	// special-needs-verification SpecialNeed.vue helpMessage included

	'specialNeed': 'necesidades especiales',
	'doesThePatientEngage': '¿El paciente se involucra en algún comportamiento que pueda poner en riesgo su seguridad física o la de los demás? (Ejemplos: conductas autolesivas como golpearse o morderse la cabeza, destrucción de propiedad, fuga, agresión verbal y/o física, etc.)',
	'specialNeedMessage': `Seleccione "sí" si alguno de los siguientes se aplica al paciente:`,
	'specialNeedMessage2': `Por favor, traiga objetos como anteojos, audífonos, andadores y dispositivos de comunicación aumentativa y alternativa (CAA) a la cita si no se necesitan otras adaptaciones.`,
	'item1': 'Habla un idioma que no sea inglés o español',
	'item2': 'Discapacidad Visual/Ciego',
	'item3': 'Discapacidad auditiva/Sordera',
	'item4': 'Problemas de movilidad (por ejemplo, incapacidad para caminar o dar pasos de forma independiente, limitaciones en brazos o piernas)',
	'item5': 'Utiliza un dispositivo de comunicación especial (por ejemplo, Dispositivo de AAC)',
	'consultationMessage': 'Uno de nuestros coordinadores de atención al paciente se comunicará con usted dentro de las 48 horas para programar su cita',
	'goToHome': 'Ir a la página de inicio',
	'specialNeedHelp': 'Si se trata de una emergencia, llame al 911. Si está pensando en hacerse daño a sí mismo o a otra persona, o si actualmente está experimentando una crisis, llame a la línea de crisis las 24 horas al 1-800-534-4673.',
	'specialNeedHelp2': 'Si tiene preguntas que no sean de emergencia, no dude en comunicarse con nosotros al 602-888-8882 durante nuestro horario comercial normal.',
	'pleaseSelect': 'Seleccione cualquiera de las siguientes circunstancias que se apliquen al paciente.',

	//form-list completingForm/List.vue

	'listMessage': `Enumere todas las personas que viven en el mismo hogar que el paciente, incluido usted mismo.`,
	'firstName': 'primer nombre',
	'gender': 'género',
	'age': 'años',
	'relation': 'Relación con la paciente',
	'addRelation': 'Agregar otra persona',
	'addMedCond16': 'Agregar otro diagnóstico o condición',

	//https://seacole.io/patients/appointment/intake/insurance-details
	'insuranceMessage': 'Por favor proporcione los detalles de su seguro',
	'secondaryInsuranceQuestion': ' ITengo seguro secundario',
	'secondaryInsuranceMessage': 'Proporcione los detalles de su seguro secundario',
	'chooseInsurance': 'Elija el proveedor de seguros',
	'banner': 'Bandera Univ',
	'arizona': 'Salud completa de Arizona',
	'test': 'Prueba (fuera de la red)',
	'gnumber': 'Número de grupo',
	'pnumber': 'Número de póliza',
	'insuranceName': 'Nombre del paciente tal como aparece en la tarjeta del seguro',
	'additionalQuestion': ' ¿Utilizarás alguna otra asistencia financiera?',
	'otherInsuranceName': 'Por favor, proporcione los detalles de otros seguros del paciente / ¿Utilizará alguna otra ayuda financiera? (i.e. ESA Funds, Gentry Scholarship, Care Credit, etc.) *',
	'secondaryInsuranceName': 'Nombre del paciente tal como aparece en la tarjeta del seguro secundario',
	'op1': 'Pago Privado',
	'op2': 'Seguro',
	'op3': 'Fondos de ESA',
	'op4': 'Beca Gentry',
	'op5': 'Sin seguro',
	'oonh': 'Su seguro está fuera de la red',
	'oond': 'Lamentablemente, no estamos en la red de su seguro en este momento. Si está interesado en el pago privado, el costo de bolsillo para la evaluación comienza en $1750 para niños y comienza en $2030 para adultos. El precio variará según si se recomienda una prueba adicional. Algunos planes de seguro ofrecen reembolsos. Consulte con su compañía de seguros para ver si esto está disponible. Algunos gastos médicos también son deducibles de impuestos. Consulte con un profesional de impuestos para obtener información al respecto. Si está interesado en la opción de pago privado, llame a nuestra oficina al 602-888-8882.',
	'uploadesa': 'Subir Registros de Fondos ESA',
	'esa_verbiage': 'Por favor sube una foto de tu Saldo de ClassWallet. Los fondos de la ESA se pueden utilizar únicamente para estudiantes de educación especial. Se requiere un IEP para Utilice esta opción y le pediremos que cargue una copia en una página diferente.',
	'uploadgentry': 'Subir Registros de Beca Gentry',
	'phFname': 'Primer nombre del titular de la póliza como aparece en la tarjeta de seguro',
	'phLname': 'Apellido del titular de la póliza como aparece en la tarjeta de seguro',
	'phDob': 'Fecha de nacimiento del titular de la póliza',
	'patientRelationInsured': 'Relación del paciente con el asegurado',
	'phAddress1': 'Dirección del titular de la póliza',
	'phAddress2': 'La dirección del titular de la póliza (por ejemplo, apartamento, habitación, piso)',
	'phGender': 'Género del titular de la póliza',
	'secondary_ins_verbiage': 'Es importante que proporcione la información de su seguro primario y secundario, incluso si se nos considera fuera de la red. No proporcionar información completa podría resultar en que se rechacen sus visitas.',
	'uploadAPictureOfInsurance': 'Cargue una fotografía de la tarjeta de seguro del paciente.',
	'frontOfCard': 'Frente de la tarjeta',
	'backOfCard': 'Reverso de la tarjeta',

	//https://seacole.io/patients/appointment/intake/BehavioralInformation1
	'behav1': 'Historia conductual y social',
	'when1': '¿Cuándo empezó a preocuparse por el desarrollo del paciente? Por favor explique',

	//https://seacole.io/patients/appointment/intake/BehavioralInformation2
	'behav2': ' Historia conductual y social',
	'what2': ' ¿Cuáles son las habilidades de comunicación y habla del paciente? Por ejemplo, cómo comunica el paciente que necesita algo, quiere algo o que algo anda mal. Por favor marque todos los que apliquen.',
	'fluent': ' Fluidez, oraciones completas, sin problemas de comunicación o habla',
	'vocal': ' Vocaliza, balbucea, hace ruidos para comunicarse',
	'points': ' Señala con el dedo lo que quiere/necesita',
	'pulls': ' Tira con el dedo a lo que quiere/necesita',
	'doesNot': ' No indica que quiere/necesita nada en absoluto',
	'usesAsl': ' Usa signos de ASL para comunicarse',
	'grabs': ' Toma la mano de otros y la usa para señalar lo que quieren/necesitan',
	'gestures': ' Hace gestos con las manos o los brazos a lo que quiere/necesita',
	'brings': ' Aporta lo que quiere/necesita a los demás',
	'single': ' Solo palabras sueltas',
	'non': ' sin hablar',
	'phrased': ' Discurso fraseado solamente',
	'cries': ' Llora, grita, tiene rabieta',
	'shutsdown': ' Se cierra, se niega a hablar o experimenta mutismo selectivo.',
	'pleaseFurther': 'Por favor explique sus seleccion(es) más',

	//https://seacole.io/patients/appointment/intake/BehavioralInformation3
	'behav3': 'Historia conductual y social',
	'has3': '¿Ha tenido alguna vez el paciente alguna dificultad para ir al baño?',
	'n3': 'Nunca',
	'p3': 'Pasado',
	'pt3': 'Presente',
	'answer3': "Si respondió 'Pasado' o 'Presente', explique con más detalle.",

	//https://seacole.io/patients/appointment/intake/BehavioralInformation4
	'behav4': 'Historia conductual y social',
	'has4': '¿Ha tenido alguna vez el paciente alguna dificultad para comer?',
	'n4': 'Nunca',
	'p4': 'Pasado',
	'pt4': 'Presente',
	'answer4': 'Describa cualquiera de los hábitos y/o dificultades alimentarias pasadas y presentes del paciente, incluidas dietas especiales o alergias alimentarias',

	//https://seacole.io/patients/appointment/intake/BehavioralInformation5
	'behav5': 'Historia conductual y social',
	'has5': '¿Ha tenido alguna vez el paciente alguna dificultad para dormir?',
	'n5': 'Nunca',
	'p5': 'Pasado',
	'pt5': 'Presente',
	'answer5': "Si respondió 'Pasado' o 'Presente', explique con más detalle.",

	//https://seacole.io/patients/appointment/intake/BehavioralInformation6
	'behav6': 'Historia conductual y social',
	'please6': 'Seleccione cualquiera de las siguientes características de comportamiento que se apliquen al paciente:',
	'a6': ' agresivo',
	'an6': ' Enfadado',
	'w6': ' Retirado',
	's6': ' Luchas con el abuso de sustancias',
	'o6': ' Demasiado activo',
	'd6': ' Distraible',
	'i6': ' Impulsivo',
	'sd6': ' Triste o deprimido',
	'l6': ' Baja autoestima',
	'sy6': ' Tímido',
	'p6': ' Perfeccionista',
	'f6': ' Amistoso',
	'fr6': ' Temeroso',
	'fg6': ' Olvidadizo',
	'c6': ' Llora mucho',
	'sa6': ' Periodo de atención corto',
	'wo6': ' se preocupa mucho',
	'n6': ' no conforme',
	'h6': ' Tiene contacto con la policía',
	'ha6': ' Contento',
	'no6': ' Ninguno de esos',

	//https://seacole.io/patients/appointment/intake/BehavioralInformation7
	'behav7': 'Historia conductual y social',
	'does7': '¿El paciente socializa e interactúa (o juega) bien con sus compañeros?',
	'y7': 'Sí',
	'n7': 'No',
	'answer7': 'Explique más y describa cómo es el comportamiento social del paciente',

	//https://seacole.io/patients/appointment/intake/BehavioralInformation8
	'behav8': 'Historia conductual y social',
	'does8': '¿El paciente tiene amigos?',
	'y8': 'Sí',
	'n8': 'No',
	'answer8': "Explique más, y si respondió 'Sí', también describa cómo se conocieron y cómo son sus interacciones.",

	//https://seacole.io/patients/appointment/intake/BehavioralInformation9
	'behav9': 'Historia conductual y social',
	'does9': '¿El paciente pertenece a algún grupo, club o equipo?',
	'y9': 'Sí',
	'n9': 'No',
	'explanation9': 'Por favor, explique. Si seleccionó `No`, por favor escriba N/A',
	//https://seacole.io/patients/appointment/intake/BehavioralInformation10
	'behav10': 'Historia conductual y social',
	'i10': '¿El paciente está asustado por alguno de los siguientes:',
	'a10': ' Animales',
	'r10': ' Vivienda áspera',
	'l10': ' Ruidos fuertes',
	't10': ' La oscuridad',
	'o10': ' Otro',
	'n10': ' Ninguno de esos',
	'answer10': 'Por favor, explique.',

	//https://seacole.io/patients/appointment/intake/BehavioralInformation11
	'behav11': 'Historia conductual y social',
	'does11': '¿El paciente busca el gusto, el tacto, la vista, el olfato o el sonido de formas inusuales?',
	'y11': 'Sí',
	'n11': 'No',
	'explanation11': 'Amplíe la explicación',
	//https://seacole.io/patients/appointment/intake/BehavioralInformation12
	'behav12': 'Historia conductual y social',
	'is12': '¿Al paciente le molesta algún sabor, tacto, vista, olor o sonido?',
	'y12': 'Sí',
	'n12': 'No',
	'explanation12': 'Amplíe la explicación',
	//https://seacole.io/patients/appointment/intake/BehavioralInformation13
	'behav13': 'Historia conductual y social',
	'does13': '¿El paciente reacciona de manera exagerada o insuficiente al dolor?',
	'y13': 'Sí',
	'n13': 'No',
	'explanation13': 'Amplíe la explicación',
	//https://seacole.io/patients/appointment/intake/BehavioralInformation14
	'behav14': 'Historia conductual y social',
	'does14': '¿El paciente tiene preocupaciones, rituales u obsesiones?',
	'y14': 'Sí',
	'n14': 'No',
	'explanation14': 'Amplíe la explicación',
	//https://seacole.io/patients/appointment/intake/BehavioralInformation15
	'behav15': 'Historia conductual y social',
	'does15': '¿Tiene el paciente algún comportamiento, movimiento o acción repetitiva?',
	'y15': 'Sí',
	'n15': 'No',
	'explanation15': 'Amplíe la explicación',
	//https://seacole.io/patients/appointment/intake/BehavioralInformation16
	'behav16': 'Historia conductual y social',
	'does16': '¿El paciente tiene comportamientos inusuales?',
	'y16': 'Sí',
	'n16': 'No',
	'explanation16': 'Amplíe la explicación',
	//https://seacole.io/patients/appointment/intake/BehavioralInformation17
	'behav17': 'Historia conductual y social',
	'please17': 'Describa cualquier otro problema de comportamiento',
	'otherConcerns17': '¿Qué otras preocupaciones tienes?',
	//https://seacole.io/patients/appointment/intake/BehavioralInformation18
	'behav18': 'Historia conductual y social',
	'p18': 'Describa los intereses del paciente y cómo le gusta pasar su tiempo.',
	'pd18': 'Describa las actividades, pasatiempos, juguetes, juegos, etc. favoritos del paciente',
	'pdp18': 'Describa la fuerza del paciente:',
	'pdpf18': 'Describa los puntos fuertes de la familia y/o del sistema de apoyo del paciente',
	'pdpft18': 'Díganos cualquier otra cosa que crea que sería útil para que comprendamos y conozcamos mejor a usted o al paciente:',

	//https://seacole.io/patients/appointment/intake/BehavioralInformation19
	'behav19': 'Historia conductual y social',
	'does19': '¿Es el paciente un peligro para sí mismo o un peligro para los demás?',
	's19': 'propio/a',
	'o19': 'otros',
	'n19': 'ninguno/a',

	//https://seacole.io/patients/appointment/intake/BehavioralInformation20
	'behav20': 'Historia conductual y social',
	'does20': '¿El paciente ha sido arrestado alguna vez?',
	'y20': 'Sí',
	'n20': 'No',
	'explanation20': 'Amplíe la explicación',

	//https://seacole.io/patients/appointment/intake/Behaviour1
	'behaviour': 'Comportamiento',
	'childHadAnyPrevPsycEvaluations': '¿El niño ha tenido alguna Evaluación Psicológica - Psiquiátrica previa?',
	'childStrengths': '¿Cuáles son las fortalezas del niño?',
	'familyStrengths': '¿Cuáles son las fortalezas de la familia?',
	'behavioralCharacteristics': 'Seleccione cualquier característica de comportamiento que se aplique a este niño.',
	'childPlayWell': '¿El niño juega bien y socializa con otros niños?',
	'pleaseExplain': 'Por favor explique',
	'doesSheHaveFriends': '¿Tiene amigos?',
	'howDidTheyMeet': 'Si tienen amigos, ¿cómo se conocieron? ¿Cómo son sus interacciones? Si no, por favor explique.',
	'childBelongToAnyGroups': '¿El niño pertenece a algún grupo, club o equipo?',
	'childInterests': '¿Cuáles son los intereses del niño?',
	'favToys': 'Juguetes o actividades favoritas',
	'anyRituals': '¿Alguna preocupación, ritual, obsesión?',

	//https://seacole.io/patients/appointment/intake/Behaviour2
	'childSeekOut': '¿Su hijo busca el tacto, la vista, el oído, el olfato o el gusto de una manera inusual?',
	'childBotheredByTouch': '¿Le molesta a su hijo alguna otra sensación táctil, visual, auditiva, olfativa o gustativa?',
	'anyRepetitiveBehaviors': 'Describe cualquier comportamiento repetitivo.',
	'anyUnusualBehaviors': 'Describe cualquier comportamiento inusual.',
	'childFrightenedBy': 'Está asustado el niño por',
	'childOverreact': '¿Su hijo reacciona de manera exagerada o insuficiente al dolor?',
	'otherBehaviourProblems': '¿Algún otro problema o preocupación de comportamiento?',

	//https://seacole.io/patients/appointment/intake/BSH2
	'behavioralHistory': 'Historia social y conductual',
	'patientCommunicationAbilities': '¿Cuáles son las capacidades de comunicación y habla del paciente? Por ejemplo, ¿cómo comunica el paciente que necesita algo, quiere algo o que algo anda mal? Por favor marque todos los que apliquen.',
	'speechIssues': 'Oraciones fluidas y completas, sin problemas de comunicación o habla.',
	'phrasedSpeechOnly': 'Sólo discurso fraseado',
	'singleWordsOnly': 'Solo palabras sueltas',
	'nonSpeaking': 'No hablar',
	'vocalBableMakeNoises': 'Vocal, balbucea, hace ruidos para comunicarse.',
	'usesSignsToCommunicate': 'Uses ASL signs to communicate',
	'pointsWithFinger': 'Señala con el dedo lo que quiere/necesita.',
	'gestureWithHands': 'Gestos con las manos o brazos hacia lo que quieren/necesitan',
	'grabsOthersHand': 'Toma la mano de otros y la usa para señalar lo que quieren/necesitan',
	'pullsOthersByHand': 'Tira de otros con la mano o con la ropa, o los empuja hacia lo que quieren o necesitan.',
	'bringsWhatTheyWant': 'Aporta lo que quieren/necesitan a los demás.',
	'criesHasTantrum': 'Llora, grita, tiene rabieta',
	'doesNotIndicate': 'No indica que quieren o necesitan nada en absoluto.',
	'explainYourSelection': 'Por favor, explique más sus selecciones.',

	//https://seacole.io/patients/appointment/intake/bsh/home
	'whenDidYouStartToWorry': '¿Cuándo empezó a preocuparse por el desarrollo del paciente? Por favor explique',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation1
	'physio1': 'Historia fisiológica y familiar',
	'did1': '“¿Uso la madre del paciente alguno de los siguientes mientras estaba embarazada de la paciente?',
	'alc1': ' Alcohol',
	'illi1': ' Sustancias ilícitas',
	'medi1': ' Medicamentos',
	'toba1': ' Tabaco',
	'none1': ' Ninguno de esos',
	'despi1': ' Por favor explique',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation2
	'physio2': 'Historia fisiológica y familiar',
	'lp2': 'Duración del embarazo con el paciente',
	'pbw2': 'Peso al nacer del paciente',
	'pbl2': 'Longitud de nacimiento del paciente',
	'tob2': 'Tipo de nacimiento',
	'vag2': 'Vaginal',
	'ces2': 'Cesárea',
	'weret2': '¿Hubo alguna complicación prenatal o de parto de la que tenga conocimiento?',
	'yp2': 'Sí',
	'np2': 'No',
	'uk2': 'Desconocida',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation3
	'physio3': 'Historia fisiológica y familiar',
	'awad3': 'A que edad el paciente primero....',
	'sa3': 'Se sentó solo?',
	'sta3': 'Se puso de pie solo?',
	'wa3': 'Camino solo?',
	'stfw3': '¿Dijo sus primeras palabras?',
	'fctw3': '¿Combino sus primaras dos palabras?',
	'fuws3': '¿Uso sus primeras oraciones de 3-4 palabras?',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation4
	'physio4': 'Historia fisiológica y familiar',
	'plaopp4': 'Enumere todos los datos del médico del paciente',
	's4': '',
	'tod4': 'Tipo de proveedor',
	'name4': 'Nombre completo del proveedor',
	'aocs4': 'Dirección o cruce de calles',
	'pon4': 'Número de teléfono',
	'addMore4': 'Agregar otro proveedor',
	'primary_care_provider': 'Proveedor de atención primaria',
	'providerNameReq': 'Se requiere el nombre completo del proveedor',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation5
	'physio5': 'Historia fisiológica y familiar',
	'hpeh5': '¿Alguna vez el paciente ha tenido alguna evaluación psicológica o psiquiátrica?',
	'yp5': 'Sí',
	'np5': 'No',
	'evaluation5': '¿Cuándo y para qué fue la evaluación?',
	'date5': 'Año de evaluación',
	'reason5': 'Motivo de la evaluación',
	'doctorname5': 'Nombre del consultorio',
	'provider5': 'Nombre del proveedor',
	'disorder5': 'Diagnóstico de trastorno psicológico o del comportamiento, Si no se da un diagnóstico, escriba N/A',
	'report5': 'Informe',
	'addAnother5': 'Agregar otra evaluación',
	'remove': 'Remove',
	'action5': 'Acción',
	'yoe_rfe_required': 'Los campos Año de evaluación y Motivo de la evaluación son obligatorios',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation6
	'physio6': 'Historia fisiológica y familiar',
	'hpeh6': '¿Alguna vez el paciente ha sido examinado para detectar anomalías cromosómicas?',
	'yp6': 'Sí',
	'np6': 'No',
	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation7
	'physio7': 'Historia fisiológica y familiar',
	'hpeh7': '¿Ha tenido el paciente algún otro diagnóstico médico?',
	'yp7': 'Sí',
	'np7': 'No',
	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation8
	'physio8': 'Historia fisiológica y familiar',
	'hpeh8': '¿Ha recibido el paciente alguna vez algún servicio o terapia?',
	'yp8': 'Sí',
	'np8': 'No',
	'facultyName': 'Nombre de la oficina',
	'frequency': 'Frecuencia',
	'typeOfService': 'Tipo de Servicios',
	'addMore8': 'Agregar otro servicio',
	'pastpresent': 'Pasado / presente',
	'past': 'Pasado',
	'present': 'presente',
	'report': 'Informe',
	'allTableFieldsRequired': 'Todos los campos de la tabla son obligatorios',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation9
	'physio9': 'Historia fisiológica y familiar',
	'hpeh9': '¿Ha tenido alguna vez el paciente lesiones graves en la cabeza o accidentes?',
	'yp9': 'Sí',
	'np9': 'No',
	'explainP9': 'Explique más, incluyendo cuándo y qué ocurrió',
	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation10
	'physio10': 'Historia fisiológica y familiar',
	'hpeh10': '¿Ha tenido el paciente alguna otra hospitalización o enfermedad grave?',
	'yp10': 'Sí',
	'np10': 'No',
	'explainP10': 'Explique más, incluyendo cuándo y qué ocurrió',
	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation11
	'physio11': 'Historia fisiológica y familiar',
	'hpeh11': '¿Ha experimentado el paciente algún trauma, abuso o negligencia?',
	'yp11': 'Sí',
	'np11': 'No',
	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation12
	'physio12': 'Historia fisiológica y familiar',
	'hpeh12': '¿Tiene el paciente actualmente algún problema de visión y/o usa lentes o lentes de contacto?',
	'yp12': 'Sí',
	'np12': 'No',
	'lb12': 'Por favor, asegúrese de que el paciente traiga/usa lentes o lentes de contacto a su cita',
	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation13
	'physio13': 'Historia fisiológica y familiar',
	'hpeh13': '¿Tiene el paciente actualmente algún problema auditivo y/o usa audífonos?',
	'yp13': 'Sí',
	'np13': 'No',
	'lb13': 'Por favor, asegúrese de que el paciente traiga/use cualquier audífono o dispositivo auditivo a cualquier cita',
	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation14
	'physio14': 'Historia fisiológica y familiar',
	'pldam14': 'Enumere y describa cualquier medicamento o suplemento que el paciente esté tomando actualmente',
	'mn14': 'Nombre del medicamento',
	'd14': 'Dosis',
	'freq14': 'Frecuencia',
	'wtmisf14': 'Razón de la medicación',
	'addMed14': 'Agregar otros medicamentos',
	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation15
	'physio15': 'Historia fisiológica y familiar',
	'hpaip15': '¿Ha pasado el paciente o alguien de su familia inmediata alguno de los siguientes en los últimos 2 años?',
	'm15': ' Matrimonio',
	'd15': ' Divorcio',
	's15': ' Separación',
	'mo15': ' Movido',
	'not15': ' Ninguno de esos',
	'pleaseFurther15': ' Por favor explique su(s) selección(es) más detalladamente:',
	'accident': ' Accidente',
	'death': ' Muerte',
	'SeriousIllness': ' Enfermedad grave',
	'OtherEvent': ' Otro evento',
	'not15': ' Ninguno de esos',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation16
	'physio16': 'Historia fisiológica y familiar',
	'plapf16': 'Describa cualquier enfermedad médica o mental importante en la familia de la paciente.',
	'fn16': 'Primer nombre',
	'ag16': 'Años',
	'rtp16': 'Relación con el paciente',
	'lod16': 'Vivo o fallecido',
	'doc16': 'Diagnóstico o Condición',

	//https://seacole.io/patients/appointment/intake/PhysiologicalInformation17
	'physio17': 'Historia fisiológica y familiar',
	'pdam17': 'Enumere todos los hermanos del paciente que NO viven en el hogar. Incluya sus nombres, sus edades y vivos o fallecidos.',
	'h0_17': 'Hermanos/as',
	'h1_17': 'Hermanos/as completos/as',
	'h2_17': 'Medio Hermanos/as',
	'h3_17': 'Hermanastros/as',
	'c1_17': 'Nombre de pila',
	'c2_17': 'Edad',
	'c3_17': 'Género',
	'c4_17': 'Vivo/a o Fallecido/a',
	'c5_17': 'Tipo',
	'addMore17': 'Agregar otro hermano',

	//https://seacole.io/patients/appointment/intake/SchoolInformation1
	'schoo1': 'Historia de la escuela',
	'dtpc1': '¿El paciente va a la escuela?',
	'ys1': 'Sí',
	'ns1': 'No',
	'tbs1': '¿A qué escuela asiste el paciente?',
	'wgs1': '¿En qué grado están ellos?',

	//https://seacole.io/patients/appointment/intake/SchoolInformation2
	'schoo2': 'Historia de la escuela',
	'dtpc2': '¿Ha experimentado el paciente alguno de los siguientes?',
	'ys2': 'Sí',
	'ns2': 'No',
	'freqAbs2': 'Ha tenido ausencias frecuentes',
	'sus2': 'Ha sido suspendido',
	'repeatGrade2': 'Ha repetido un grado',
	'expelled2': 'ha sido expulsado',
	'noneOf2': 'Ninguno de esos',
	'explainFur2': 'Por favor explique',

	//https://seacole.io/patients/appointment/intake/SchoolInformation3
	'schoo3': 'Historia de la escuela',
	'dtpc3': '¿Se le ha pedido o se le ha pedido al paciente que repita un grado en la escuela?',
	'ys3': 'Sí',
	'ns3': 'No',

	//https://seacole.io/patients/appointment/intake/SchoolInformation4
	'schoo4': 'Historia de la escuela',
	'dtpc4': '¿El paciente ha sido o ha sido alguna vez suspendido de la escuela?',
	'ys4': 'Sí',
	'ns4': 'No',
	//https://seacole.io/patients/appointment/intake/SchoolInformation5
	'schoo5': 'Historia de la escuela',
	'dtpc5': '¿El paciente ha sido o ha sido expulsado alguna vez en la escuela?',
	'ys5': 'Sí',
	'ns5': 'No',
	//https://seacole.io/patients/appointment/intake/SchoolInformation6
	'schoo6': 'Historia de la escuela',
	'dtpc6': '¿El paciente ha sido o alguna vez evaluado en la escuela para educación especial, un IEP, plan 504 o programa para superdotados?',
	'ys6': 'Sí',
	'ns6': 'No',
	'tbs6': 'Por favor explique',
	'tbsi6': 'Cargue cualquier documentación relacionada a continuación.',

	//https://seacole.io/patients/appointment/intake/SchoolInformation7
	'schoo7': 'Historia de la escuela',
	'dtpc7': '¿Ha recibido o ha recibido alguna vez el paciente servicios de educación especial en la escuela?',
	'ys7': 'Sí',
	'ns7': 'No',
	'sph7': 'Por favor, proporcione más información',

	//https://seacole.io/patients/appointment/intake/SchoolInformation8
	'schoo8': 'Historia de la escuela',
	'dtpc8': '¿Cuál es tu nivel educativo más alto?',
	'sph8': 'Por favor, explique más detalladamente" or "Por favor, proporcione más información',

	//https://seacole.io/patients/appointment/intake/SchoolInformation9
	'schoo9': 'Historia de la escuela',
	'dtpc9': '¿Cuál es tu nivel educativo más alto?',
	'sph9': 'Por favor, explique más detalladamente" or "Por favor, proporcione más información',

	//https://seacole.io/patients/appointment/intake/UploadRecords
	'uploadr': 'Cargar registros',
	'uploadmers': 'Suba cualquier documento adicional que pueda ser útil para que el proveedor complete la evaluación del paciente.',
	'uploadf': 'Subir Documentos',
	'filen': 'Nombre del archivo:',
	'file': 'archivo',
	'type': 'tipo',
	'action': 'acción',
	'upload': 'subir',
	'noFiles': 'Ningún Archivo Escogido',
	'uploadingProgress:': 'Progreso de la carga:',

	//https://seacole.io/patients/appointment/intake/provider-finder
	'headpf': '¿Cómo le gustaría elegir a su especialista de evaluación?',
	'c1pf': 'Idioma principal del paciente',
	'c2pf': 'Idioma principal del padre/tutor',
	'c3pf': 'Ubicación preferida',

	//https://seacole.io/patients/appointment/intake/provider-list-form
	'hplf': 'Seleccione Psicómetra',

	///https://seacole.io/patients/appointment/intake/authority-parent
	'PAH': '¿Quién tiene autoridad para tomar decisiones médicas para este menor?',
	'MDA': 'Autoridad Médica Menor',
	'TLISH': 'Dos padres o tutores legalmente autorizados que vivan en el mismo hogar',
	'TNLISH': 'Dos padres legalmente autorizados o legales.	tutores que no vivan en el mismo hogar, con o sin orden judicial.',
	'OWCO': 'Uno de los padres con orden judicial u otra documentación legal',
	'OWOCO': 'Ninguno de esos',
	'legallyAuthorized': '¿Están ambos padres o tutores legalmente autorizados enumerados en el certificado de nacimiento o en la documentación judicial?',

	//https://seacole.io/patients/appointment/intake/upload-court-order

	'UCO': 'Upload Court Order',

	//https://seacole.io/patients/appointment/intake/partner-consent-info

	'PCI': 'Informationen zur Einwilligung des Partners',
	'PE': 'Ingrese el correo electrónico de su socio para el enlace de consentimiento',
	'EI': 'Se enviará un correo electrónico al otro progenitor con un enlace para obtener su consentimiento para reservar el apartamento para niños.',
	'unableToProvidePartnerInfo': 'No puedo proporcionar información de socio o me gustaría recibir una llamada de un coordinador de atención al paciente antes de enviar consentimientos secundarios.',

	//https://seacole.io/patients/appointment/intake/partner-consent-info
	'parentGuardian': 'Tutora',
	'selectType': 'Seleccione tipo',
	'type': 'Tipo',
	'lastName': 'Apellido',
	'middleInitial': 'Inicial del segundo nombre',
	'dob': 'Fecha de nacimiento',
	'occupation': 'Ocupación',
	'sex': 'Sexo',
	'homeAddressSameAsClient': '¿La dirección de casa es la misma que la del cliente?',
	'homePhone': 'Teléfono de casa',
	'cellPhone': 'Teléfono móvil',
	'workPhone': 'Teléfono del trabajo',
	'highestLevelOfEduCompleted': 'Mayor Nivel de Educación Completado',

	//https://seacole.io/patients/appointment/intake/attestation
	'attestationForm': 'Formulario de Certificación',
	'attestConfirm': 'Al firmar a continuación, doy fe de que la información proporcionada en este documento es verdadera y precisa a mi leal saber y entender.',
	'saveAttestation': 'Salvar',

	//https://seacole.io/patients/appointment/intake/school-form
	'school': 'Escuela',
	'doesChildAttendSchool': '¿El niño asiste actualmente a la escuela?',
	'hasChildBeenAttainedFromSchool': '¿Alguna vez el niño ha sido retenido en la escuela?',
	'hasChildBeenSuspendedFromSchool': '¿Alguna vez un niño ha sido suspendido de una escuela?',
	'hasChildBeenExpelledFromSchool': '¿Alguna vez han expulsado a un niño de una escuela?',
	'hasChildBeenTestedAtSchool': '¿Alguna vez el niño ha sido evaluado en la escuela recientemente?',
	'hasChildBeenTestedAsGifted': '¿Alguna vez se ha probado al niño como superdotado?',
	'doesChildReceiveSpecialEdu': '¿Recibe su hijo servicios de educación especial?',

	//https://seacole.io/patients/appointment/intake/care-cordinator
	'careCoordinatorWillReachOut': 'Nuestro equipo se comunicará con usted en los próximos días hábiles para discutir la próxima pasos necesarios para programar su evaluación. Si tienes alguna pregunta, por favor contáctenos al (602) 888-8882.',

	//https://seacole.io/patients/appointment/intake/insurance-gentry-scholarship-stop
	'gentryScholarshipStopUploadedDocuments': 'Si cargó documentos, nuestro equipo los revisará en los próximos días hábiles y se comunicará con usted para analizar cualquier problema o le enviará un enlace por correo electrónico para continuar con el proceso de admisión.',
	'gentryScholarshipStopNotUploadedDocuments': 'Si no cargó documentos, nuestro equipo se comunicará con usted para analizar lo que se requiere para continuar.',
	'gentryScholarshipStopContact': 'Si tiene alguna pregunta, comuníquese con nosotros al (602) 888-8882.',

	'nextSteps': 'Próximos Pasos',

	//https://seacole.io/patients/appointment/intake/care-cordinator2
	'emailHasSent': 'Se envió un correo electrónico al otro padre con el enlace de consentimiento; una vez aprobado, recibirá un enlace de continuación por correo electrónico. Gracias por su paciencia.',
	'okay': 'Bueno',

	//https://seacole.io/patients/appointment/intake/care-cordinator3
	'thankYouYouWillReceiveLink': 'Thank you! You will receive a link to continue with scheduling once the court order is reviewed.',

	//https://seacole.io/patients/appointment/intake/adult-patient-notification
	'callUsToRegisterAdultPatient': 'Si está interesado en registrar a un paciente adulto, por favor contáctenos al (602) 888-8882.',

	//https://seacole.io/patients/appointment/intake/care-cordinator-contact
	'careCoordinatorWillReachOutIn1to4': 'Un coordinador de atención al paciente se comunicará con usted dentro de 1 a 4 días hábiles para ayudarlo. Llame al 602-888-8882 si tiene alguna pregunta.',

	//https://seacole.io/patients/appointment/intake/provider-filter
	'filter': 'Filtrar',
	'lang': 'Idioma',
	'age': 'Edad',
	'ageRange': 'Age Range',
	'above': 'Arriba',
	'gender': 'Género',
	'male': 'Masculino',
	'female': 'Femenino',
	'specialities': 'Especialidades',
	'autism': 'Autismo',
	'psyciatary': 'Psyciatary',
	'otherLang': 'Otro idioma',

	//https://seacole.io/patients/appointment/intake/provider-list
	'noProviderFound': 'No se encontró ningún proveedor con el idioma seleccionado.',

	//https://seacole.io/patients/appointment/intake/provider-specific-time
	'pickADay': 'Elige día y hora para la cita',
	'eachAppointFor2hrs': 'Cada cita es por 2 horas.',

	//https://seacole.io/patients/appointment/intake/growth-progress1
	'growthProg': 'Progreso del crecimiento',
	'childFirstSitAlone': '¿A qué edad se sentó su hijo solo por primera vez?',
	'childFirstStandAlone': '¿A qué edad estuvo su hijo solo por primera vez?',
	'childFirstWords': '¿A qué edad dijo su hijo sus primeras palabras?',
	'childFirstCombineWords': '¿A qué edad su hijo combinó dos palabras por primera vez?',
	'childFirst34Words': '¿A qué edad su hijo utilizó por primera vez oraciones de 3 o 4 palabras?',
	'anyDifficultyDuringToileting': 'Indique cualquier dificultad que su hijo haya tenido para ir al baño.',
	'explain': 'Explicar',
	'difficultyEating': 'Indique cualquier dificultad que su hijo haya tenido para comer.',
	'difficultySleeping': 'Indique cualquier dificultad que su hijo haya tenido para dormir.',

	//https://seacole.io/patients/appointment/intake/growth-progress-2
	'worryAboutChildDevelopment': '¿Cuándo empezó a preocuparse por el desarrollo del niño?',
	'anyPrevDiagnosis': '¿El niño tiene algún diagnóstico previo? En caso afirmativo, especifique',
	'receivingAnySpecialService': '¿Está recibiendo algún servicio especial actualmente? En caso afirmativo, indique y por quién.',
	'seriousIllness': '¿Alguna enfermedad grave u hospitalización?',
	'seriousHeadInjuries': '¿Alguna lesión grave en la cabeza o accidente?',
	'visionProblems': '¿Problemas de visión o gafas?',
	'hearingProblems': 'Hearing problems or hearing aids?',
	'childHasTraumaHistory': '¿Su hijo ha tenido antecedentes de algún trauma, abuso o negligencia?',
	'childDiagnoseWithDisorder': '¿Se le ha diagnosticado al niño algún trastorno psicológico o de conducta?',
	'upload': 'Subir',

	//https://seacole.io/patients/appointment/intake/insurance-photo
	'addPhoto': 'Agreguemos una foto de su tarjeta.',
	'whenAddPhoto': 'Al agregar fotografías, coloque su tarjeta sobre una superficie plana para obtener mejores resultados. Le pediremos fotografías del anverso de la tarjeta y luego del reverso.',
	'addPhotos': 'Agregar fotos',
	'addManually': 'Agregar manualmente',
	'skipThis': 'SALTAR ESTO',

	//https://seacole.io/patients/appointment/intake/insurance-pre-auth
	'insuranceRequiresPreAuth': 'Se requiere autorización previa',
	'patientCareCoordinatorWillReachOut': 'Desafortunadamente, su proveedor de seguros requiere autorización previa antes de que podamos programar su evaluación de autismo. Un coordinador de atención al paciente se comunicará con usted para analizar los próximos pasos. Si tiene alguna pregunta, comuníquese con nuestra oficina al (602) 888-8882.',

	//https://seacole.io/patients/appointment/intake/insurance-private-pay
	'privatePayStartsAt1': 'Si está interesado en el pago privado, el costo de bolsillo de la evaluación comienza en',
	'privatePayStartsAt2': 'Para niños y comienza en',
	'privatePayStartsAt3': 'para adultos. Un coordinador de atención al paciente se comunicará con usted dentro de las 48 horas para ayudarlo. Llame al 602-888-8882 si tiene alguna pregunta.',

	//https://seacole.io/patients/appointment/intake/insurance-verify
	'isYourPrimaryInsurance': '¿Es este su seguro principal?',

	//https://seacole.io/patients/appointment/intake/secondary-insurance
	'patientRelationToInsured': 'Relación del paciente con el asegurado',
	'zipCodeNotInUS': 'El código postal no corresponde a ningún estado o ciudad de EE. UU.',

	//https://seacole.io/patients/appointment/intake/patient-age
	'patientAge': 'Por favor díganos la edad de su paciente.',
	'groupNo': 'Número de grupo',

	//https://seacole.io/patients/appointment/intake/patient-information1
	'tellAboutPatient': 'Por favor cuéntenos sobre su paciente',
	'childFirstName': 'Nombre del niño',
	'childLastName': 'Apellido del niño',
	'childMiddleIntials': 'Inicial del segundo nombre del niño',
	'placeOfBirth': 'Lugar de nacimiento',

	//https://seacole.io/patients/appointment/intake/patient-information2
	'homeAddress': 'Direccion de casa',
	'residenceCountry': 'País de Residencia',

	//https://seacole.io/patients/appointment/intake/patient-information3
	'nickName': 'Apodo',
	'socialSecurityNumber': 'Número de seguro social',
	'ethnicity': 'Etnicidad',

	//https://seacole.io/patients/appointment/intake/patient-information4
	'primaryLang': 'Lenguaje primario',
	'langAtHome': 'Idioma que se habla en casa',
	'withWhomeChildLive': '¿Con quién vive el niño?',

	//https://seacole.io/patients/appointment/intake/payments/checkout-form
	'checkoutForm': 'FORMULARIO DE PAGO',
	'cardholderName': 'Nombre del titular de la tarjeta',
	'expiration': 'vencimiento',
	'cardNumber': 'número de tarjeta',
	'valid': 'VÁLIDO',
	'thru': 'A TRAVÉS DE',
	'secCode': 'código de seguridad',

	//https://seacole.io/patients/appointment/intake/payments/payment-form
	'amountToBePaid': 'El monto a pagar es',
	'payFull': 'Pagar completo',
	'payHalf': 'PAGA LA MITAD',

	//https://seacole.io/patients/appointment/intake/payments/payment-option-covered
	'paymentOptionCovered': 'Opciones de pago cubiertas',

	//https://seacole.io/patients/appointment/intake/payments/payment-option-not-covered
	'paymentOptionNotCovered': 'Opciones de pago no cubiertas',

	//https://seacole.io/patients/appointment/intake/all-set
	'allSet': 'You&apos;re all set!',
	'keepALookOut': 'Esté atento a más formularios e información necesarios para la cita.',
	'goToHome': 'Ir a la página de inicio',

	//https://seacole.io/patients/appointment/intake/birth-information
	'birthMotherUse': '¿La madre biológica usó alguno de los siguientes durante el embarazo?',
	'lengthOfPreg': 'Duración del embarazo',
	'birthWeight': 'Peso de nacimiento',
	'birthHeight': 'Altura de nacimiento',
	'typeOfBirth': 'Tipo de nacimiento',

	//https://seacole.io/patients/appointment/intake/diet
	'diet': 'Dieta',
	'listMedications': 'Enumere cualquier medicamento o suplemento que reciba el niño (medicamento, dosis, hora(s) de administración)',
	'eatingHabits': 'Describir los hábitos alimentarios.',
	'anySpecialDiet': 'Cualquier dieta especial o alergia alimentaria (Sí o No). Si es así, por favor describa',
	'prevPsycEval': '¿El niño ha tenido alguna Evaluación Psicológica - Psiquiátrica previa?',
	'whatAreChildStrengths': '¿Cuáles son las fortalezas del niño?',
	'whatAreFamilyStrengths': '¿Cuáles son las fortalezas de la familia?',

	//https://seacole.io/patients/appointment/intake/family-information
	'parentsAreSeperated': 'Si los padres están separados/divorciados, ¿quién tiene la custodia?',
	'noOfSiblings': 'Numero de hermanos',
	'otherMembersLiving': '¿Otros miembros que viven en el hogar?',
	'pleaseListRelationsToClient': 'En caso afirmativo, indique nombres, sexo, edad y relación con el cliente.',
	'familyExperience': '¿La familia experimentó alguno de los siguientes en los últimos dos (2) años?',
	'medicatlIllnessInFamily': 'Describa cualquier enfermedad médica o mental importante en la familia.',
	'useDuringPreg': '¿La madre biológica usó alguno de los siguientes durante el embarazo?',

	//https://seacole.io/patients/appointment/intake/modality
	'likeToBeSeen': '¿Le gustaría que lo atendieran en la oficina o de forma remota en casa?',
	'atHome': 'En casa',
	'remote': 'Remoto',

	//https://seacole.io/patients/appointment/intake/physician
	'tellAboutPhys': 'Por favor cuéntenos sobre el médico de su hijo.',
	'address': 'DIRECCIÓN',

	//https://seacole.io/patients/appointment/intake/reason
	'reasonForEval': 'Motivo de la evaluación',
	'patientToBeSeen': 'Describa por qué desea que atiendan a su paciente en este momento.',
	'yourGoals': '¿Cuáles son sus objetivos para la evaluación?',

	//https://seacole.io/patients/appointment/intake/school
	'school': 'Escuela',
	'childAttendSchool': '¿El niño asiste actualmente a la escuela?',
	'childRetainedAtSchool': '¿Alguna vez el niño ha sido retenido en la escuela?',
	'childEverSuspended': '¿Alguna vez un niño ha sido suspendido de una escuela?',
	'childEverExpelled': '¿Alguna vez han expulsado a un niño de una escuela?',
	'childEverTested': '¿Alguna vez el niño ha sido evaluado en la escuela recientemente?',
	'childEverTestedGifted': '¿Alguna vez se ha probado al niño como superdotado?',
	'childReceiveSpecialEdu': '¿Recibe su hijo servicios de educación especial?',

	//https://seacole.io/patients/appointment/intake/someone-will-reach-out
	'someoneWillReach': 'Alguien se acercará.',

	//https://seacole.io/patients/appointment/intake/tell-us
	'tellUs': 'Dinos',
	'tellUsAnything': 'Díganos cualquier cosa que crea que sería útil para comprenderlo a usted o a este niño. Incluya las preguntas que pueda tener.',

	//https://seacole.io/patients/appointment/intake/upload-records
	'download': 'Descargar',

	//https://seacole.io/patients/appointment/intake/invoice
	'patientDetials': 'Detalles del paciente',
	'shortPhone': 'Teléfono',
	'shortEmail': 'Correo electrónico',
	'appointDetails': 'Detalles de la cita',
	'invoiceName': 'Nombre de la factura',
	'invoiceId': 'ID de factura',
	'details': 'Detalles',
	'paymentType': 'Tipo de pago',
	'fees': 'Honorarios',
	'paymentOn': 'Pago en',
	'contactDetails': 'Detalles de contacto',
	'note': 'Nota',
	'subtotalAmt': 'Importe subtotal',
	'discount': 'Descuento',
	'grandTotal': 'Gran total',
	'makeAPayment': 'REALIZAR UN PAGO',
	'incorrectToken': 'Proporciona un token incorrecto para la factura o la factura no creada con este token.',
	'contactAdmin': 'Por favor contacte al administrador.',

	//https://seacole.io/patients/appointment/intake/profile/account
	'myAccount': 'Mi cuenta',

	//https://seacole.io/patients/appointment/intake/profile
	'myProfile': 'Mi perfil',
	'logout': 'Cerrar sesión',

	//https://seacole.io/patients/appointment/intake/provider-finder-reschedule
	'chooseYourAssess': '¿Cómo le gustaría elegir a su especialista en evaluación?',
	'patPrimaryLang': 'Idioma primario de los pacientes',
	'shortSelect': 'Seleccionar',

	//https://seacole.io/patients/appointment/intake/provider-list-reschedule
	'selectPsyc': '¿Seleccionar psiquiatra/terapeuta?',

	//https://seacole.io/patients/appointment/intake/provider-specific-time-reschedule
	'pickADay': 'Elija un día y hora para reprogramar la cita',
	'appointFor2hrs': 'cada cita es por 2 horas.',

	//https://seacole.io/patients/appointment/checkout
	'checkoutOnlyForm': 'FORMULARIO SÓLO PARA PAGO',
	'pay': 'PAGAR',

	//https://seacole.io/patients/appointment/messages
	'messages': 'Mensajes',
	'noMessages': 'Ningún mensaje',

	//https://seacole.io/patients/appointment/library
	'library': 'Biblioteca',
	'libraryComingSoon': '¡La biblioteca llegará pronto!',

	//https://seacole.io/patients/appointment/library
	'myMembership': 'Mi membresía',
	'membershipComingSoon': '¡La membresía llegará pronto!',

	//https://seacole.io/patients/appointment/my-records
	'myRecords': 'Mis registros',
	'recordsComingSoon': '¡Pronto llegarán los récords!',

	//https://seacole.io/patients/appointment/my-tasks
	'myTasks': 'Mis tareas',
	'noTasks': 'Sin tareas',

	//https://seacole.io/patients/appointment/release-of-information-form
	'save': 'Ahorrar',

	// /patients/pages/intake/consent/ConsentClinicalServicesForm.vue
	'consentForClinical': 'CONSENTIMIENTO PARA SERVICIOS CLÍNICOS',
	'profRecords': 'Registros profesionales',
	'lawStandards': `la ley y los estándares de nuestra profesión exigen que su médico
	mantenga registros de tratamiento. Tiene derecho a recibir una copia de sus registros o su
	médico puede prepararle un resumen. Nota: los materiales con derechos de autor utilizados en
	diagnósticos y/o información privilegiada no se incluirían en el conjunto de registros. Debido a
	que se trata de registros profesionales, pueden ser mal interpretados y/o molestos para lectores
	no capacitados. Si desea ver sus registros, le recomendamos que los revise en presencia de su
	médico para que se pueda discutir el contenido. Se cobrarán honorarios profesionales por el
	tiempo dedicado a responder a las solicitudes de información. Las tarifas se pueden solicitar
	comunicándose con nuestra oficina al 602-888-8882.`,
	'patientConf': 'Confidencialidad del paciente/médico',
	'inGeneralLaw': `en general, la ley protege la privacidad de todas las
	comunicaciones entre un tutor legal o un paciente adulto y un psicólogo, y su médico puede
	divulgar información a otros sólo con su permiso por escrito. Pero hay algunas excepciones.
	En la mayoría de los procedimientos legales, usted tiene derecho a impedir que su médico
	proporcione información sobre la evaluación y el tratamiento del paciente. En algunos
	procedimientos relacionados con la custodia de los hijos y aquellos en los que su condición
	emocional es un tema crítico, un juez puede ordenar el testimonio de su médico si determina
	que el tema lo exige. Hay algunas situaciones en las que su médico está legalmente obligado a
	tomar medidas para proteger al paciente y a otras personas de daños, incluso si debe revelar
	cierta información sobre la evaluación/tratamiento de un paciente. Por ejemplo:`,
	'ifYourClinicianBelievesAChild': `Si su médico cree que un niño, una persona mayor, una persona discapacitada, etc., está
	sufriendo abuso o abandono, debe presentar un informe ante la agencia estatal
	correspondiente.`,
	'ifYourClinicianBelievesARisk': `Si su médico cree que usted corre un riesgo inminente de hacerse daño, puede comunicarse
	con las autoridades u otros servicios de crisis. Sin embargo, antes de comunicarse con los
	servicios de emergencia o crisis, su médico trabajará con usted para analizar otras opciones
	para mantenerlo seguro.`,
	'ifYourClinicianBelievesAThreat': `Si su médico cree que existe una amenaza específica y creíble (definida por la ley estatal) de
	daño a otra persona, la ley le exige que tome medidas. Esto significa que si un paciente, tutor
	legal o cualquier otra persona relacionada/involucrada amenaza con causar daño corporal
	grave a otra persona, su médico debe tomar medidas que pueden incluir notificar a la víctima
	potencial, comunicarse con la policía y/o buscar hospitalización para el individuo y/o contactar a
	familiares u otras personas que puedan ayudar a brindar protección.`,
	'ifAnotherHealthProvEngaging': `Si informa que otro proveedor de atención médica está teniendo un comportamiento
	inapropiado (incluido un comportamiento que no representa una amenaza para la seguridad),
	es posible que se le solicite a su médico que informe esta información a la junta de licencias
	correspondiente. Su médico hablará con usted sobre cómo realizar este informe primero y solo
	compartirá la información mínima necesaria al realizarlo. Si su médico debe compartir su
	información personal sin obtener su permiso primero, solo compartirá la información mínima
	necesaria. En algunas ocasiones, es posible que su médico no pueda mantener legalmente su
	información personal confidencial.`,
	'yourClinicianNeedToConsult': `En ocasiones, es posible que su médico necesite consultar a otros profesionales sobre un caso
	o recibir la supervisión necesaria para obtener la licencia. Durante una sesión de
	consulta/supervisión, hacen todo lo posible para evitar revelar la identidad del individuo. El
	consultor/supervisor también está legalmente obligado a mantener la confidencialidad de la
	información. Si no se opone, su médico no le informará sobre estas consultas a menos que
	considere que es importante para su trabajo conjunto.`,
	'writtenNoticeToConfidentiality': `Si bien este aviso escrito de excepciones a la confidencialidad es útil para informarle sobre
	posibles problemas, es importante que usted y su médico analicen cualquier pregunta o
	inquietud que pueda tener. Estarán encantados de discutir estos temas con usted si necesita
	asesoramiento específico, pero es posible que necesite asesoramiento legal formal porque las
	leyes que rigen la confidencialidad son bastante complejas y su médico no es un abogado.`,
	'dndEvals': 'Evaluaciones de Diagnóstico y Desarrollo',
	'privatePayRateServices': `Las tarifas de pago privadas por servicios se
	pueden obtener llamando a nuestra oficina al 602-888-8882 (si no utiliza seguro). Las tarifas
	incluyen observaciones, evaluaciones y calificaciones de evaluaciones, entrevistas con
	maestros/tutores (si corresponde), redacción de informes y asistencia a reuniones.`,
	'bnrEvals': 'Beneficios y Riesgos de las Evaluaciones',
	'riskEnvolvedInAssess': `Aunque los riesgos involucrados en la evaluación son mucho menores que durante la
	psicoterapia tradicional, los límites de la confidencialidad son los mismos y se pueden leer en la
	sección de confidencialidad de este acuerdo.`,
	'diagnosticConclusionAreNotExpected': `Es posible que las conclusiones del diagnóstico que saquemos no sean las que esperaba. Por
	lo tanto, si bien la evaluación y el diagnóstico a menudo conducen a servicios apropiados,
	también pueden dificultar la obtención de servicios (como podría ser el caso de un paciente que
	no recibe un diagnóstico). Al realizar una evaluación con un menor, su tutor tiene derecho a ver el informe final. El tutor de un menor/paciente puede solicitar y completar un formulario de
	divulgación de información (ROI) si desean que compartamos el informe con otras entidades.`,
	'axisForAutismSpecialty': `Axis for Autism es un proveedor especializado y puede no ser el proveedor más adecuado
	para usted o su familiar. Si nos damos cuenta de esto, le derivaremos a otros profesionales que
	creemos que son más adecuados para ayudarle.`,
	'diagnosticeEvalInvolveCommitment': `Las evaluaciones diagnósticas implican un gran compromiso de tiempo y energía por parte de
	los pacientes y familiares. No es un proceso rápido. Informe a su equipo de atención lo antes
	posible si tiene dudas sobre los compromisos de tiempo.`,
	'inLegalProceedings': `En algunos procedimientos legales, un juez puede ordenar el testimonio de su proveedor si
	determina que los temas así lo exigen, y debemos cumplir con esa orden judicial.`,
	'riskOfTreatmentInvolveDisagreement': `Uno de los riesgos del tratamiento infantil implica desacuerdos entre los padres y/o
	desacuerdos entre los padres y el médico con respecto al tratamiento del niño. Si se producen
	tales desacuerdos, el médico se esforzará por comprender sus puntos de vista y explicar
	completamente los suyos. Podemos resolver dichos desacuerdos, o podemos aceptar no estar
	de acuerdo, siempre que esto permita el estándar de atención médicamente necesario. Debido
	a los requisitos de documentación médica, toda la información está documentada y cualquier
	información compartida por cualquiera de las partes será visible para todas las partes que
	tengan derechos legales para revisar el registro. Esto significa que la información compartida
	por uno de los padres estará disponible para que el otro padre la revise.`,
	'courtOrderToTestifyInCustodyLitigation': `Si el tribunal ordena testificar en un litigio de custodia, nuestros psicólogos están obligados
	éticamente a no dar una opinión sobre la custodia, la idoneidad de las visitas o la aptitud de
	cualquiera de los padres. Si el tribunal designa un evaluador de custodia, un tutor ad litem o un
	coordinador de crianza, proporcionaremos la información necesaria, si se firman las
	autorizaciones correspondientes o se proporciona una orden judicial. Además, si se requiere
	que un psicólogo comparezca como testigo o realice algún otro trabajo relacionado con
	cualquier tema legal, la parte responsable de la participación/requisito acepta pagar a Axis for
	Autism una tarifa por hora por el tiempo dedicado a viajar y hablar con abogados, revisión y
	preparación de documentos, testificación, asistencia y cualquier otro costo relacionado con el
	caso.`,
	'signBelowAcknowlegde': 'Con mi firma a continuación, reconozco',
	'readAllInfo': `He leído toda la información contenida en este Documento de Consentimiento Informado para
	Servicios Clínicos y he sido completamente informado sobre la naturaleza de la evaluación, los
	riesgos involucrados y los beneficios deseados.`,
	'legalyCompetent': 'Soy legalmente competente y tengo la autoridad para dar consentimiento para la evaluación.',
	'volutarilyAgree': `Acepto voluntariamente recibir una evaluación de diagnóstico y entiendo que puedo
	suspender los servicios por escrito en cualquier momento.`,
	'agreeToTerms': 'Acepto los términos y condiciones anteriores',
	'signBelow': 'Firma',

	// /patients/pages/intake/consent/NoticeHealthInformationPractices.vue
	'nhip_noticeOfHIP': 'Aviso de Prácticas de Información de Salud',
	'nhip_recievingNoticeBcz': `Usted está recibiendo este aviso porque su proveedor de atención médica participa en una
	organización sin fines de lucro, intercambio de información de salud no gubernamental (HIE)
	llamado Health Current. No le costará nada y puede ayudar a su médico, proveedores de
	atención médica y planes de salud a coordinar mejor su atención al compartir de forma segura
	su información de salud. Este Aviso explica cómo funciona el HIE y lo ayudará a comprender
	sus derechos con respecto al HIE según las leyes estatales y federales.`,
	'nhip_howHealthCurrHelp': 'Cómo le ayuda Health Current a obtener una mejor atención?',
	'nhip_inPaperBasedRecord': `En un sistema de registros en papel, su información de salud se envía por correo o por fax a su
	médico, pero a veces estos registros se pierden o no llegan a tiempo para su cita. Si permite
	que su información de salud se comparta a través del HIE, sus médicos podrán acceder a ella
	electrónicamente de manera segura y oportuna.`,
	'nhip_healthInfoAvailable': 'Qué información de salud está disponible a través de Health Current?',
	'nhip_typesOfHealthInfo': 'Los siguientes tipos de información de salud pueden estar disponibles:',
	'nhip_hospitalRecords': 'Registros hospitalarios',
	'nhip_medicalHistory': 'Historial médico',
	'nhip_medications': 'Medicamentos',
	'nhip_allergies': 'Alergias',
	'nhip_testResults': 'Resultados de pruebas de laboratorio',
	'nhip_radiologyReport': 'Informes de radiología',
	'nhip_clinicVisitInfo': 'Información sobre visitas clínicas y médicas',
	'nhip_healthPlanEnrol': 'Inscripción y elegibilidad en planes de salud',
	'nhip_otherInfoNedded': 'Otra información necesaria para el tratamiento',
	'nhip_whoViewYourHelathInfo': `Quién puede ver su información de salud a través de Health Current y cuándo se puede	compartir?`,
	'nhip_peopleInvolved': `Las personas involucradas en su atención tendrán acceso a su información de salud. Esto
	puede incluir a sus médicos, enfermeras, otros proveedores de atención médica, plan de salud
	y cualquier organización o persona que trabaje en nombre de sus proveedores de atención
	médica y su plan de salud. Pueden acceder a su información para tratamiento, coordinación de
	la atención, administración de atención o casos, planificación de la transición de la atención,
	pago de su tratamiento, realización de actividades de mejora y evaluación de la calidad,
	desarrollo de pautas y protocolos clínicos, realización de actividades de seguridad del paciente
	y servicios de salud de la población. Los médicos forenses, las autoridades de salud pública,
	las organizaciones de obtención de órganos y otros también pueden acceder a información de
	salud para ciertos fines aprobados, como realizar investigaciones de muerte, investigaciones de
	salud pública y donación y trasplante de órganos, ojos o tejidos, según lo permita la ley
	aplicable.`,
	'nhip_healthCurrentMayUse': `Health Current también puede utilizar su información de salud según lo exige la ley y según sea
	necesario para realizar servicios para proveedores de atención médica, planes de salud y otros
	que participan en Health Current. La Junta Directiva de Health Current puede ampliar las
	razones por las cuales los proveedores de atención médica y otras personas pueden acceder a su información de salud en el futuro, siempre que el acceso esté permitido por la ley. Esa
	información se encuentra en el sitio web de Health Current en healthcurrent.org/permitted-use.`,
	'nhip_permitOtherToAccess': `También puede permitir que otros accedan a su información de salud firmando un formulario de
	autorización. Sólo podrán acceder a la información de salud descrita en el formulario de
	autorización para los fines establecidos en ese formulario.`,
	'nhip_doesHealthCurrRecievesBehaviorInfo': `Health Current recibe información sobre salud conductual y, de ser así, quién puede acceder a ella?`,
	'nhip_healthCurrRecievesBehavInfo': `Health Current recibe información de salud conductual, incluidos registros de tratamiento de
	abuso de sustancias. La ley federal brinda protección de confidencialidad especial a los
	registros de tratamiento de abuso de sustancias de algunos programas de tratamiento de
	abuso de sustancias. Health Current mantiene estos registros protegidos de tratamiento de
	abuso de sustancias separados del resto de su información de salud. Health Current solo
	compartirá estos registros protegidos de tratamiento de abuso de sustancias que recibe de
	estos programas en dos casos. Primero, el personal médico puede acceder a esta información
	en caso de emergencia médica. Segundo, usted puede firmar un formulario de consentimiento
	que le otorga a su proveedor de atención médica u otras personas acceso a esta información.`,
	'nhip_howHealthInfoProtected': 'Cómo se protege su información de salud?',
	'nhip_federalAndStateLaws': `Las leyes federales y estatales, como HIPAA, protegen la confidencialidad de su información
	médica. Su información se comparte mediante transmisión segura. Health Current cuenta con
	medidas de seguridad para evitar que alguien que no esté autorizado tenga acceso. Cada
	persona tiene un nombre de usuario y contraseña, y el sistema registra todos los accesos a su
	información.`,
	'nhip_rightsReagrdingSecureElecInfoSharing': 'Sus Derechos Con Respecto al Intercambio Seguro de Información Electrónica',
	'nhip_youHaveRightTo': 'Tiene derecho a',
	'nhip_askCopyOfHealthInfo': `Solicitar una copia de su información de salud que esté disponible a través de Health
	Current. Para realizar esta solicitud, complete el Formulario de solicitud de información
	médica y devuélvalo a su proveedor de atención médica.`,
	'nhip_reqToHaveInfoCorrected': `Solicitar que se corrija cualquier información contenida en el HIE. Si alguna información
	en el HIE es incorrecta, puede pedirle a su proveedor de atención médica que la corrija.`,
	'nhip_askForPeopleWhoViewed': `Solicite una lista de personas que han visto su información a través de Health Current.
	Para realizar esta solicitud, complete el Formulario de solicitud de información médica y
	devuélvalo a su proveedor de atención médica. Informe a su proveedor de atención
	médica si cree que alguien ha visto su información y no debería haberlo hecho.`,
	'nhip_youHaveRightUnderArticle27': `Tiene derecho, según el artículo 27, sección 2 de la Constitución de Arizona y los
	Estatutos Revisados de Arizona, título 36, sección 3802, a evitar que su información de
	salud se comparta electrónicamente a través de Health Current:`,
	'nhip_optOutOfSharingYourInfo': `Salvo que la ley estatal o federal disponga lo contrario, usted puede “optar por no” que
	su información se comparta a través de Health Current. Para optar por no participar,
	solicite a su proveedor de atención médica el formulario de exclusión voluntaria. Su
	información no estará disponible para compartir a través de Health Current dentro de los 30 días posteriores a que Health Current reciba su formulario de exclusión voluntaria de
	su proveedor de atención médica. Precaución: Si opta por no participar, su información
	de salud NO estará disponible para sus proveedores de atención médica, incluso en
	caso de emergencia.`,
	'nhip_optOutAndChangeMindLater': `Si opta por no participar hoy, puede cambiar de opinión en cualquier momento
	completando un formulario de optar por volver a participar voluntaria y devolviéndolo a
	su proveedor de atención médica.`,
	'nhip_optOutInFuture': `Si no hace nada hoy y permite que su información de salud se comparta a través de
	Health Current, puede optar por no participar en el futuro.`,
	'nhip_infoWillBeSecurelyShared': `SI NO HACE NADA, SU INFORMACIÓN PUEDE SER COMPARTIDA DE FORMA SEGURA A TRAVÉS DE HEALTH CURRENT.`,
	'nhip_noticeOfHIPInitials': 'Aviso de Prácticas de Información de Salud (todas las iniciales)',
	'nhip_ackThatIReadNHIP': 'Reconozco que recibí y leí el Aviso de prácticas de información de salud. (inicial)',
	'nhip_myHealthProvParticipatesInHealthCurr': `Entiendo que mi proveedor de atención médica participa en Health Current, el
	intercambio de información de salud (HIE) de Arizona. (inicial)`,
	'nhip_myInfoBeSecurelyShared': `Entiendo que mi información de salud puede compartirse de forma segura a través del
	HIE, a menos que complete y devuelva un formulario de exclusión voluntaria a mi proveedor de
	atención médica. (inicial)`,
	'nhip_agreeToTerms': 'Acepto los términos y condiciones anteriores',
	'nhip_signBelow': 'Firma',

	// /patients/pages/intake/consent/NoticePrivacyPractices.vue
	'npp_noticeOfPrivacyPractices': 'Aviso de Prácticas de Privacidad',
	'npp_axisAutismCommittedToProtect': `Axis for Autism, LLC se compromete a proteger la privacidad de la información de salud de
	nuestros pacientes. De acuerdo con la Ley de Responsabilidad y Portabilidad del Seguro
	Médico (HIPAA), este aviso describe nuestras prácticas de privacidad y explica cómo podemos
	usar y divulgar su información médica protegida (PHI) para llevar a cabo tratamientos, pagos u
	operaciones de atención médica y para otros fines que están permitidos o requeridos por la ley.
	Axis for Autism está obligado, por ley, a mantener la privacidad y confidencialidad de su
	información médica protegida y para brindarles a nuestros pacientes aviso sobre nuestras
	obligaciones legales y prácticas de privacidad con respecto a su información médica protegida.`,
	'npp_termsOfNoticeApplytoRecords': `TLos términos de este aviso se aplican a todos los registros que contienen su PHI que Axis for
	Autism crea o conserva. Nos reservamos el derecho de revisar o modificar este Aviso de
	prácticas de privacidad. Cualquier revisión o enmienda a este aviso será efectiva para todos
	sus registros que nuestra práctica haya creado o mantenido en el pasado, y para cualquiera de
	sus registros que podamos crear o mantener en el futuro. Axis for Autism publicará una copia
	de nuestro Aviso actual en nuestras oficinas en un lugar visible en todo momento y usted puede
	solicitar una copia de nuestro aviso más reciente en cualquier momento.`,
	'npp_whenBeginWorkingWithAxis': `Cuando comienza a trabajar con Axis for Autism, se realiza un registro del tratamiento. Este
	registro contiene su historial, evaluación, información médica, diagnóstico, tratamiento, un plan
	para tratamientos futuros, etc. Esta información a menudo se le conoce como registro médico y
	sirve como:`,
	'npp_basisForPlanning': 'La base para planificar su atención y tratamiento.',
	'npp_docDescribingCare': 'Un documento legal que describa la atención que recibió.',
	'npp_meanByWhichVerify': `Un medio por el cual usted o un tercero pagador pueden verificar que se proporcionaron los servicios facturados.`,
	'npp_sourceOfData': `Una fuente de datos para los funcionarios encargados de mejorar la atención o los servicios necesarios.`,
	'npp_toolByWhichServicesApproved': 'Una herramienta mediante la cual se pueden aprobar servicios futuros o continuos.',
	'npp_understandingWhatIsInRecords': `Comprender lo que hay en este registro lo ayudará a garantizar su exactitud, comprender mejor
	quién, qué, cuándo y por qué otros pueden acceder a su información y ayudarlo a tomar
	decisiones informadas al autorizar la divulgación a otros. Aunque su registro de salud es
	propiedad física de Axis for Autism, la información le pertenece a usted. Usted tiene los
	siguientes derechos:`,
	'npp_toInspectYourPHI': `Inspeccionar y copiar su PHI que la empresa utiliza para tomar decisiones sobre usted, con
	ciertas excepciones. La empresa le responderá dentro de los treinta (30) días.`,
	'npp_reqARestrictionOnUse': `Solicitar una restricción sobre nuestro uso y divulgación de su PHI. La empresa podrá negar la
	solicitud si no es razonable o sería perjudicial para el tratamiento.`,
	'npp_paperCopyOfNotice': 'Una copia impresa de este Aviso',
	'npp_reqCompanyToAmend': `Solicitar a la empresa que modifique la PHI que mantiene sobre usted si considera que es
	incorrecta o está incompleta mientras la empresa conserve la información. Para solicitar una enmienda, debe presentar una solicitud por escrito a su supervisor asignado e indicar el motivo
	que respalda su solicitud. La información en disputa permanecerá en el registro junto con la
	información enmendada. La empresa puede rechazar su solicitud si la solicitud no se presenta
	por escrito, no contiene una razón que respalde la solicitud, la información que se cuestiona no
	se originó en la empresa, no es parte de la información que se le permite inspeccionar o copiar,
	o que sea exacto y completo actualmente.`,
	'npp_obtainAccountingOfDisclosures': `Obtener un informe de las divulgaciones que la empresa hizo con respecto a su PHI. Excluye
	las divulgaciones que la empresa pueda haberle hecho a usted, si usted nos autorizó a realizar
	la divulgación para un directorio de la empresa, a familiares o amigos involucrados en su
	atención, con fines de notificación, para seguridad o inteligencia nacional, a las autoridades
	policiales (según lo dispuesto). en la regla de privacidad) o instalaciones correccionales, como
	parte de una divulgación de conjunto de datos limitado. El derecho a recibir esta información
	está sujeto a ciertas excepciones, restricciones y limitaciones.`,
	'npp_reqCommunicationOfHealthInfo': `Solicitar la comunicación de su información de salud por medios alternativos o ubicación
	alternativa. Por ejemplo, puede solicitar que el médico solo se comunique con usted en el
	trabajo o por correo. Para solicitar comunicaciones por medios alternativos, deberá presentar
	su solicitud por escrito a su supervisor asignado. No se le preguntará el motivo de su solicitud y
	su solicitud será acomodada. Su solicitud debe indicar cómo y dónde desea que lo
	contactemos.`,
	'npp_exmpOfDisclosureForTreatment': `Ejemplo de divulgación para tratamiento, pago y operaciones de atención médica: La compañía
	utilizará su información de salud para evaluación de diagnóstico, recomendaciones de
	tratamiento y tratamientos y servicios continuos. La compañía puede divulgar su información de
	salud protegida al personal de la agencia y no perteneciente a la agencia que pueda estar
	involucrado en su tratamiento. La compañía utilizará y divulgará su información de salud
	protegida para que pueda realizarse la facturación y el pago de los servicios del tratamiento.
	Para fines de facturación y pago, podemos divulgar información a compañías de seguros o de
	atención administrada, a Medicaid o a terceros pagadores. La compañía utilizará su
	información de salud para operaciones regulares de atención médica. Estos usos y
	divulgaciones son necesarios para administrar la agencia y nuestra calidad de atención.`,
	'npp_exmpOfDisclosuresForOtherPurposes': `Ejemplos de usos y divulgaciones para otros fines específicos: según lo exige la ley, la
	empresa puede divulgar su información de salud protegida o la de su dependiente. Podemos
	usar o divulgar su información de salud protegida en las siguientes situaciones sin su
	autorización o sin brindarle la oportunidad de aceptar u objetar. Estas situaciones incluyen,
	entre otras:`,
	'npp_reqByLaw': 'Requerido por la ley:',
	'npp_weMayDiscloseYourProtectedHealthInfo': `Podemos usar o divulgar su información de salud protegida en la
	medida en que el uso o divulgación sea requerido por la ley. El uso o divulgación se realizará
	de conformidad con la ley y se limitará a los requisitos pertinentes de la ley. Se le notificará, si
	así lo exige la ley, de dichos usos o divulgaciones.`,
	'npp_pbHealth': 'Salud Pública:',
	'npp_mayDiscloseHealthInfoForHealthActivities': `Podemos divulgar su información de salud protegida para actividades y
	propósitos de salud pública a una autoridad de salud pública que esté autorizada por ley a
	recopilar o recibir la información. Por ejemplo, se puede hacer una divulgación con el fin de
	prevenir o controlar enfermedades, lesiones o discapacidades.`,
	'npp_communicateDiseases': 'Enfermedades Transmisibles:',
	'npp_mayDiscloseHealthInfoToAPersonWithExposedDisease': `Podemos divulgar su información médica protegida, si lo
	autoriza la ley, a una persona que pueda haber estado expuesta a una enfermedad
	transmisible o que de otro modo pueda estar en riesgo de contraer o propagar la enfermedad o
	condición.`,
	'npp_healthOversight': 'Supervisión de la Salud:',
	'npp_mayDiscloseHealthInfoToHealthAgency': `Podemos divulgar información de salud protegida a una agencia de
	supervisión de la salud para actividades autorizadas por la ley, como auditorías,
	investigaciones e inspecciones. Las agencias de supervisión que buscan esta información
	incluyen agencias gubernamentales que supervisan el sistema de atención médica, programas
	de beneficios gubernamentales, otros programas regulatorios gubernamentales y leyes de
	derechos civiles.`,
	'npp_abuseOrNeglect': 'Abuso o Negligencia:',
	'npp_mayDiscloseHealthInfoToHealthAuthority': `Podemos divulgar su información de salud protegida a una autoridad
	de salud pública que esté autorizada por ley para recibir informes de abuso o negligencia
	infantil. Además, podemos divulgar su información de salud protegida si creemos que ha sido
	víctima de abuso, negligencia o violencia doméstica a la entidad o agencia gubernamental
	autorizada para recibir dicha información. En este caso, la divulgación se hará de conformidad
	con los requisitos de las leyes federales y estatales aplicables.`,
	'npp_legalProceedings': 'Procedimientos Legales:',
	'npp_mayDiscloseHealthInfoForJudicial': `Podemos divulgar información de salud protegida en el curso de
	cualquier procedimiento judicial o administrativo, en respuesta a una orden de un tribunal o
	tribunal administrativo (en la medida en que dicha divulgación esté expresamente autorizada), o
	en ciertas condiciones en respuesta a una citación, solicitud de descubrimiento u otro proceso
	legal.`,
	'npp_lawEnforcement': 'Aplicación de la Ley:',
	'npp_mayDiscloseHealthInfoForLawEnforcement': `También podemos divulgar información de salud protegida, siempre
	que se cumplan los requisitos legales aplicables, para fines de aplicación de la ley. Estos
	propósitos de aplicación de la ley incluyen (1) procesos legales y otros requeridos por la ley, (2)
	solicitudes de información limitada para fines de identificación y ubicación, (3) relacionadas con
	las víctimas de un delito, (4) sospecha de que la muerte ha ocurrido como resultado de
	conducta criminal, (5) en caso de que ocurra un delito en las instalaciones de nuestra práctica,
	y (6) emergencia médica (no en las instalaciones de nuestra práctica) y es probable que haya
	ocurrido un delito.`,
	'npp_ciminalActivity': 'Actividad Criminal:',
	'npp_consistentWithApplicableLaw': `De acuerdo con las leyes federales y estatales aplicables, podemos
	divulgar su información de salud protegida, si creemos que el uso o la divulgación es necesaria
	para prevenir o disminuir una amenaza grave e inminente a la salud o seguridad de una
	persona o del público. También podemos divulgar información de salud protegida si es
	necesario que las autoridades encargadas de hacer cumplir la ley identifiquen o detengan a un
	individuo.`,
	'npp_militaryActivity': 'Actividad Militar y Seguridad Nacional:',
	'npp_whenAppropriateConditionsApply': `cuando se apliquen las condiciones apropiadas,
	podremos usar o divulgar información de salud protegida de personas que sean personal de las
	Fuerzas Armadas (1) para las actividades que las autoridades de comando militar apropiadas
	consideren necesarias; (2) con el fin de que el Departamento de Asuntos de Veteranos
	determine su elegibilidad para recibir beneficios, o (3) ante una autoridad militar extranjera si es
	miembro de esos servicios militares extranjeros. También podemos divulgar su información
	médica protegida a funcionarios federales autorizados para realizar actividades de inteligencia
	y seguridad nacional, incluso para la prestación de servicios de protección al Presidente u otras
	personas legalmente autorizadas.`,
	'npp_authorizationApply': `Se requiere su autorización para otros usos de la información de salud protegida:`,
	'npp_willDiscloseHealthInfoWithWrittenAuthroization': `el médico usará y divulgará información de salud protegida (que no sea la descrita en este Aviso
	o la requerida por la ley) solo con su autorización por escrito. Puede revocar su autorización
	para usar o divulgar información de salud protegida por escrito, en cualquier momento. Si
	revoca su autorización, ya no usaremos ni divulgaremos su información médica protegida para
	los fines cubiertos por la autorización, excepto cuando ya hayamos confiado en la autorización.`,
	'npp_otherInvolvedInHealthCare': 'Otras personas involucradas en su atención médica o en el pago de su atención:',
	'npp_mayDiscloseToFamilyMember': `a	menos que usted se oponga, podemos divulgar a un miembro de su familia, un pariente, un
	amigo cercano o cualquier otra persona que usted identifique, su información de salud
	protegida que se relacione con la participación de esa persona. en su atención médica. Si usted
	no puede aceptar u oponerse a dicha divulgación, podemos divulgar dicha información según
	sea necesario si determinamos que es lo mejor para usted según nuestro criterio profesional.
	Podemos usar o divulgar información de salud protegida para notificar o ayudar a notificar a un
	miembro de la familia, representante personal o cualquier otra persona responsable de su
	atención sobre su ubicación, condición general o muerte.`,
	'npp_mayDiscloseToPublicOrPrivateEntity': `Finalmente, podemos usar o divulgar su información médica protegida a una entidad pública o
	privada autorizada para ayudar en los esfuerzos de socorro en casos de desastre y para
	coordinar usos y divulgaciones a familiares u otras personas involucradas en su atención médica.`,
	'npp_responsibilityRegardingDependents': 'Nuestra responsabilidad con respecto a su información de salud protegida o la de su dependiente',
	'npp_clinicianRequiredByLaw': 'El médico está obligado por ley a:',
	'npp_maintainThePrivacy': 'Mantener la privacidad de su PHI.',
	'npp_provideWithNoticeOurLegalDuties': `Proporcionarle un aviso sobre nuestras obligaciones legales y prácticas de privacidad
	con respecto a la información que recopilamos y mantenemos sobre usted.`,
	'npp_abideByTerms': 'Cumplir con los términos de este aviso.',
	'npp_notifyIfUnableToAgree': 'Notificarle si no podemos aceptar una restricción solicitada.',
	'npp_accomodateRequests': `Acomodar las solicitudes razonables que usted pueda tener para comunicar
	información de salud por medios alternativos o en lugares alternativos.`,
	'npp_socialWebsites': 'Redes Sociales/Sitios Web de Reseñas',
	'npp_tryToCommunicateWithClinicianViaMedia': `Si intenta comunicarse con su médico a través de plataformas de redes sociales, no
	responderá. Esto incluye cualquier forma de solicitud de amistad o contacto, (at)mención,
	mensaje directo, publicación en el muro, etc. Esto es para proteger su confidencialidad y
	garantizar límites apropiados.`,
	'npp_clinicianMayPublishOnMedia': `Su médico puede publicar contenido en varios blogs o sitios web de redes sociales. No existe
	ninguna expectativa de que usted siga, comente o interactúe de otro modo con cualquier
	contenido. Si elige seguir a su médico en cualquier plataforma, él no le seguirá.`,
	'npp_seeClinicianOnAnyReviewWebsite': `Si consulta a su médico en cualquier tipo de sitio web de revisión, no se trata de una solicitud
	de revisión. Muchos de estos sitios recopilan listados de empresas y pueden incluir automáticamente a su médico. Si elige dejar una reseña de su médico en cualquier sitio web,
	este no responderá. Si bien siempre es libre de expresarse de la manera que elija, tenga en
	cuenta el posible impacto en su confidencialidad antes de dejar una reseña. A menudo es
	imposible eliminar reseñas más adelante y algunos sitios agregan reseñas de varias
	plataformas, lo que hace que su reseña aparezca en otros lugares sin su conocimiento.`,
	'npp_signatureBelowIndicatesYouReadInfo': `Su firma a continuación indica que ha leído la información de este documento y acepta cumplir
	con sus términos durante su relación profesional médico-paciente.`,
	'npp_changesToNoticeOfPrivacyPractices': 'Cambios a este Aviso de Prácticas de Privacidad',
	'npp_companyReservesTheRightToMakeChanges': `La compañía se reserva el derecho de realizar cambios a este aviso siempre que haya un
	cambio material en los usos o divulgaciones, sus derechos individuales, nuestras obligaciones
	legales u otras prácticas de privacidad establecidas en este Aviso. Cualquier cambio realizado
	afectará la información de salud protegida que mantenemos en ese momento. Mantenemos
	una copia del aviso actual en nuestra oficina. Proporcionaremos una copia revisada del aviso a
	los tutores legales cuando lo soliciten en la fecha de entrada en vigor de la revisión o después.`,
	'npp_concerns': 'Preocupaciones',
	'npp_concernsAboutPrivacyRights': `Las inquietudes sobre sus Derechos de Privacidad o cómo la empresa ha manejado su PHI
	deben dirigirse al departamento de cumplimiento llamando al 602-888-8882 o enviando un
	correo electrónico a security(at)axisforautism.com. No será penalizado por presentar una queja.
	Si no está satisfecho con la forma en que esta oficina maneja su queja, puede presentar una
	queja formal a:`,
	'npp_dhhsOffice': 'DHHS, Office of Civil Rights',
	'npp_200IndpAvn': '200 Independence Avenue,',
	'npp_swRoom': 'S.W. Room 509HHH Building',
	'npp_washingtonDC': 'Washington, DC 20201',
	'npp_haveReadAxisAutismNoticeOfPrivacyPractices': `He leído Axis for Autism - Aviso de prácticas de privacidad y entiendo mis derechos contenidos
	en el aviso. Mediante mi firma, proporciono a la empresa mi autorización y consentimiento para
	usar y divulgar mi PHI con fines de tratamiento, pago y operaciones de atención médica como
	se describe en el Aviso de Privacidad.`,
	'npp_date': 'Fecha:',
	'npp_agreeToTerms': 'Acepto los términos y condiciones anteriores',
	'npp_signBelow': 'Firma',

	// /patients/pages/intake/consent/ConsentGeneralForm.vue
	'gcf': 'Formulario de Consentimiento General',
	'gcf_professionalServicesInformation': `Lo siguiente contiene información importante sobre las políticas y servicios profesionales de la empresa, así como los derechos y expectativas de los tutores durante la evaluación y/o el tratamiento. Lea atentamente cada sección. Si tiene alguna pregunta, hable con un Coordinador antes de firmar o poner sus iniciales. Si no está satisfecho con las respuestas que recibe, llame a su oficina local y pregunte por el supervisor.`,
	'gcf_mustInitialEachTime': 'El tutor/paciente debe poner sus iniciales en cada elemento para indicar comprensión y acuerdo.',
	'gcf_electronicCommuication': 'Comunicaciones Electrónicas',
	'gcf_useOfElectronicMail': 'Autorizo el uso del correo electrónico (correo electrónico) y mensajes (mensajes de texto) para toda correspondencia conmigo mismo, incluida la programación, la recopilación de información y la entrega de informes y otros documentos oficiales. Entiendo que esto significa que se pueden enviar documentos con información de salud protegida a través de Internet y que Internet no siempre está completamente protegido contra personas no autorizadas. Entiendo que el consentimiento puede retirarse en cualquier momento notificando a Axis for Autism, por escrito, a la siguiente dirección: Axis for Autism, 1645 E. Missouri Ave, Suite 320, Phoenix, AZ 85016',
	'gcf_attendance': 'Asistencia y programación',
	'gcf_accompanyingThePatient': 'si el paciente o cualquier persona que lo acompañe a su cita está enfermo (es decir, temperatura superior a 99.5 °F, secreción nasal verde, diarrea, vómitos, cualquier condición infecciosa, contagiosa o infestación de cualquier tipo, etc.), por favor reprogramar su cita. Es importante que nuestros médicos se mantengan saludables para no tener que cancelar sesiones futuras con usted u otros pacientes. Si el paciente o cualquier persona que lo acompañe a su cita está enfermo o no se siente bien, comuníquese con su médico lo antes posible para que se le pueda asignar ese horario a otro paciente.',
	'gcf_lateCancellation': 'Cargo por cancelación tardía',
	'gcf_lateCancellationFee': 'Se puede cobrar un cargo por cancelación tardía de $50 por cualquier cita cancelada con menos de 24 horas de anticipación. Esto no aplica para citas reprogramadas. Si la reprogramación con menos de 24 horas ocurre dos veces, el médico puede comunicarse con el padre/tutor legal o el paciente adulto para asegurar una cita reprogramada final. La terminación de los servicios puede ser necesaria si continúan las cancelaciones excesivas.',
	'gcf_contactingUs': 'Contáctenos',
	'gcf_notImmediatelyAvailable': 'Los médicos de Axis for Autism a menudo no están disponibles de inmediato por teléfono. Si bien generalmente trabajamos entre las 8 a. m. y las 5 p. m., no contestaremos el teléfono si estamos con otro paciente. Si no puede comunicarse con su médico y siente que no puede esperar a que le devuelvan la llamada, llámenos o envíenos un mensaje de texto al (602) 888-8882, o envíenos un correo electrónico a scheduling(at)axisforautism.com. Su médico hará todo lo posible para devolverle su llamada telefónica, mensaje de texto o correo electrónico dentro de las 48 horas, excepto los fines de semana y días festivos, o en caso de que esté fuera del consultorio por algún motivo. Si es difícil comunicarse con usted, informe a su médico los horarios en los que estará disponible.',
	'gcf_notImmediatelyAvailableHighlight': 'Si usted o un miembro de su familia tiene una emergencia relacionada con la salud mental/conductual u otra emergencia que pueda requerir atención médica inmediata, llame al 911, llame o envíe un mensaje de texto a la Línea Nacional de Suicidio y Crisis al 988, comuníquese con su médico de familia o llame/vaya. a la sala de emergencias más cercana, ya que no brindamos servicios de emergencia en nuestra oficina.',
	'gcf_extendedHoliday': 'Si su médico no estará disponible durante un feriado prolongado, le proporcionará el nombre de un colega con quien contactar, si es necesario.',
	'gcf_telehealth': 'Consentimiento de telesalud',
	'gcf_telehealthInformation': 'Este Consentimiento informado para servicios de telesalud contiene información importante que se centra en la prestación de servicios mediante el teléfono o Internet. Lea esto atentamente y háganos saber si tiene alguna pregunta. Cuando firme este documento, representará un acuerdo entre usted y Axis for Autism, LLC.',
	'gcf_telehealthRisk': 'Beneficios y riesgos de la telesalud',
	'gcf_telehealthRishExplaination': 'Uno de los beneficios de la telesalud es que el cliente y el médico pueden recibir servicios sin estar en el mismo lugar físico. Esto puede ser útil para garantizar la prestación del servicio y la continuidad de la atención si el cliente o el médico se muda a un lugar diferente, se toma unas vacaciones prolongadas o no puede continuar reuniéndose en persona. También es más cómodo y lleva menos tiempo. La telesalud, sin embargo, requiere competencia técnica de ambas partes para que sea útil. Aunque la telesalud tiene beneficios, existen algunas diferencias entre la telesalud en persona y la telesalud, así como algunos riesgos. Por ejemplo:',
	'gcf_telehealthRiskConfidentiality': 'Riesgos para la confidencialidad. Las leyes que protegen la confidencialidad también se aplican a la telesalud, aunque puede haber limitaciones o riesgos adicionales. Debido a que la telesalud se lleva a cabo fuera del consultorio privado de un médico, donde se puede garantizar la privacidad y la confidencialidad, existe la posibilidad de que otras personas escuchen las sesiones si usted no se encuentra en un lugar privado durante la sesión. Por nuestra parte, tomaremos medidas razonables para garantizar su privacidad. Pero es importante que te asegures de encontrar un lugar privado para nuestra sesión donde no te interrumpan. También es importante que protejas la privacidad de nuestra sesión en tu teléfono celular u otros dispositivos. Debe participar en telesalud solo mientras se encuentre en una habitación o área donde no haya otras personas presentes y no puedan escuchar la conversación.',
	'gcf_telehealthTechnology': 'Cuestiones relacionadas con la tecnología. Hay muchas formas en que los problemas tecnológicos pueden afectar la telesalud. Estos pueden incluir, entre otros, la posibilidad de que la transmisión de su información médica pueda verse interrumpida o distorsionada por fallas técnicas; la transmisión de información podría ser interrumpida por personas o empresas no autorizadas; y/o pueden ocurrir malentendidos entre usted y su proveedor más fácilmente.',
	'gcf_telehealthCrisisManagement': 'Gestión e intervención de crisis. Por lo general, no participaremos en telesalud con clientes que se encuentren actualmente en una situación de crisis que requiera altos niveles de apoyo e intervención. Antes de participar en la telesalud, desarrollaremos un plan de respuesta de emergencia para abordar posibles situaciones de crisis que puedan surgir durante el curso de nuestro trabajo de telesalud.',
	'gcf_telehealthEfficacy': 'Eficacia. La mayoría de las investigaciones muestran que la telesalud es tan eficaz como los servicios presenciales. Sin embargo, algunos terapeutas creen que algo se pierde al no estar en la misma habitación. Por ejemplo, existe un debate sobre la capacidad de un terapeuta para comprender completamente la información no verbal cuando trabaja de forma remota.',
	'gcf_telehealthElectronic': 'Comunicaciones Electrónicas',
	'gcf_teleheathElectronicComputer': 'Es posible que deba tener ciertos sistemas de computadora o teléfono celular para utilizar los servicios de telesalud. Usted es el único responsable de cualquier costo para obtener el equipo, los accesorios o el software necesarios para participar en la telesalud.',
	'gcf_telehealthConfidentiality': 'Confidencialidad',
	'gcf_telehealthConfidentialityLegal': 'Tenemos la responsabilidad legal y ética de hacer nuestros mejores esfuerzos para proteger todas las comunicaciones que forman parte de la telesalud. Sin embargo, la naturaleza de las tecnologías de comunicaciones electrónicas es tal que no podemos garantizar que nuestras comunicaciones se mantengan confidenciales o que otras personas no puedan acceder a nuestras comunicaciones. Intentaremos utilizar métodos de cifrado, firewalls y sistemas de respaldo actualizados para ayudar a mantener la privacidad de su información, pero existe el riesgo de que nuestras comunicaciones electrónicas puedan ser comprometidas, no seguras o accedidas por otras personas. Usted también debe tomar medidas razonables para garantizar la seguridad de nuestras comunicaciones (por ejemplo, usar solo redes seguras para sesiones de telesalud y tener contraseñas para proteger el dispositivo que usa para telesalud).',
	'gcf_telehealthConfidentialityException': 'El alcance de la confidencialidad y las excepciones a la confidencialidad que describimos en nuestro Consentimiento informado aún se aplican en la telesalud. Al aceptar la telesalud, usted acepta que comprende que no tenemos control total sobre quién escucha la información que discutimos. También acepta permitir que su hijo realice la visita en un espacio privado, si es posible, o notificar al médico si otras personas pueden escuchar o ver la sesión cuando se discute información confidencial. Háganos saber si tiene alguna pregunta sobre las excepciones a la confidencialidad.',
	'gcf_telehealthAppropriateness': 'Idoneidad de la Telesalud',
	'gcf_telehealthAppropriatenessForm': 'Le informaremos si decidimos que la telesalud no es la forma de tratamiento más adecuada para usted. Discutiremos opciones para participar en servicios en persona o referencias a otro profesional en su ubicación que pueda brindar los servicios adecuados. Para ciertas personas, solicitamos que un facilitador adulto esté presente en la sala para ayudar con dificultades técnicas o mantener al niño concentrado. Los padres/tutores tienen derecho a retener o retirar el consentimiento a la telesalud en cualquier momento sin afectar el derecho a la atención futura del tratamiento. Se aplica el mismo nivel de estándares éticos y profesionales a los servicios de telesalud que a la prestación de servicios en persona.',
	'gcf_telehealthEmergencies': 'Emergencias y Tecnología',
	'gcf_telehealthEmergenciesSession': 'Durante las sesiones de telesalud, valorar y evaluar amenazas y otras emergencias puede resultar más difícil cuando se realiza telesalud que en la terapia tradicional en persona. Para abordar algunas de estas dificultades, contamos con un plan de emergencia para contratar servicios de telesalud. Le pediremos que identifique una persona de contacto de emergencia que esté cerca de su ubicación y con quien nos comunicaremos en caso de una crisis o emergencia para ayudar a abordar la situación.  Su firma en este documento nos permite comunicarnos con su persona de contacto de emergencia o con la línea directa de crisis estatal al 1-844-534-HOPE (4673) según sea necesario durante dicha crisis o emergencia.',
	'gcf_telehealthEmergenciesSessionInterrupted': 'Si la sesión se interrumpe por cualquier motivo, como falla la conexión tecnológica, y estás teniendo una emergencia, no vuelvas a llamar a tu proveedor; en su lugar, llame al 911, llame o envíe un mensaje de texto al 988 o vaya a la sala de emergencias más cercana. Vuelva a llamar a su proveedor de telesalud después de haber llamado u obtenido servicios de emergencia.',
	'gcf_telehealthEmergenciesSessionInterruptedNotEmergency': 'Si la sesión se interrumpe y no tiene una emergencia, desconéctese de la sesión y esperaremos dos (2) minutos y luego nos volveremos a comunicar con usted a través de la plataforma de telesalud en la que acordamos realizar la terapia. Si no recibe una llamada dentro de dos (2) minutos, llame a su proveedor al número de teléfono que le proporcionamos.',
	'gcf_telehealthEmergenciesTechnological': 'Si hay una falla tecnológica y no podemos reanudar la conexión, solo se le cobrará la cantidad prorrateada del tiempo real de la sesión.',
	'gcf_telehealthInstate': 'Servicios Solo Dentro del Estado',
	'gcf_telehealthInstateChild': 'Nuestros médicos tienen licencia a nivel estatal. Por lo tanto, no podemos brindar teleservicios a ningún niño que no se encuentre en el mismo estado o en un estado de Psypact en el momento de la sesión. Al comienzo de su visita, su proveedor le pedirá la dirección donde recibe los servicios de telesalud para confirmar que se encuentra en el estado y garantizar que haya una ubicación precisa para usted en caso de una emergencia.',
	'gcf_telehealthInstateFee': 'Honorarios',
	'gcf_telehealthInstateFeeRate': 'Se aplicarán las mismas tarifas para la telesalud que para los servicios en persona. Sin embargo, es posible que los seguros u otros proveedores de atención administrada no cubran las sesiones que se realizan a través de telecomunicaciones. Si su seguro, HMO, pagador externo u otro proveedor de atención administrada no cubre las sesiones de telesalud electrónica, usted será el único responsable de la tarifa total de la sesión. Comuníquese con su compañía de seguros antes de participar en sesiones de telesalud para determinar si estas sesiones estarán cubiertas.',
	'gcf_telehealthInstateRecord': 'Registros',
	'gcf_telehealthInstateRecordAgree': 'La evaluación será registrada por Axis for Autism, LLC. Usted y su hijo aceptan que la sesión será grabada y revisada por un psicólogo autorizado. Axis for Autism puede utilizar las grabaciones para la capacitación de sus empleados. Si no desea que la grabación de su hijo se utilice con fines de capacitación, escriba sus iniciales en el cuadro a continuación. Mantendremos un registro de nuestra sesión de la misma manera que mantenemos registros de las sesiones en persona de acuerdo con nuestras políticas.',
	'gcf_telehealthAcknowledge': 'Reconozco que mi hijo será grabado con fines de diagnóstico.',
	'gcf_teleheathAcknowledgeNot': 'NO quiero que la grabación de mi hijo utilizada con fines de capacitación.',
	'gcf_teleheathPhotos': 'Está estrictamente prohibido grabar o tomar fotografías por cualquier persona en la sala debido a la protección de derechos de autor. Al firmar este consentimiento, usted acepta esta política.',
	'gcf_telehealthFeedback': 'La sesión de comentarios de telesalud entre el tutor y el psicólogo no se grabará de ninguna manera a menos que se acuerde por escrito de mutuo acuerdo. Mantendremos un registro de nuestra sesión de la misma manera que mantenemos registros de las sesiones en persona de acuerdo con nuestras políticas. Al aceptar la telesalud, usted y su hijo aceptan que la sesión no será grabada, reproducida ni publicada, que no se harán copias de los materiales ni se compartirá el contenido.',
	'gcf_telehealthHipaa': 'HIPAA/BAA',
	'gcf_telehealthZoom': 'Entiendo que Axis for Autism utiliza el software de videoconferencia Zoom for HealthCare para brindar telesalud. El modelo de prestación de telesalud se utiliza durante la pandemia de COVID-19 en numerosos estados de los Estados Unidos. Entiendo que Axis for Autism ha celebrado un Acuerdo de Socio Comercial ("BAA") con Zoom que permitirá el cumplimiento de HIPAA para toda la telesalud. De conformidad con la BAA, Zoom es responsable de mantener segura toda la información del paciente y de informar violaciones de seguridad que involucren información médica personal. Zoom no tiene acceso a información de salud identificada y Zoom protege y cifra todos los datos de audio, video y pantalla compartida.',
	'gcf_telehealthAuthorization': 'Este consentimiento y autorización son válidos hasta que usted los revoque por escrito. Su firma a continuación indica que está de acuerdo con sus términos y condiciones.',
	'gcf_covid': 'Formulario de consentimiento COVID-19',
	'gcf_covidInformation': 'Este documento contiene información importante sobre nuestra decisión (la suya y la mía) de reanudar los servicios en persona considerando el COVID-19. Lea esto detenidamente e informe a la empresa si tiene alguna pregunta. Cuando firme este documento, será un acuerdo oficial entre usted y la empresa.',
	'gcf_covidDecision': 'La Decisión de Reunirse Cara a Cara',
	'gcf_covidDecisionAgreed': 'Hemos acordado reunirnos en persona para una evaluación de diagnóstico o servicios de tratamiento. Sin embargo, si hay un resurgimiento de la pandemia o si surgen otros problemas de salud, es posible que necesitemos reprogramar más pruebas o reunirnos a través de telesalud. Si tiene dudas sobre reunirse a través de telesalud, hablaremos de ello primero e intentaremos abordar cualquier problema. Usted comprende que, si creemos que es necesario, podemos determinar que volvamos a la telesalud para el bienestar de todos.',
	'gcf_covidDecisionAnytime': 'Si decide en cualquier momento que se sentiría más seguro continuando con los servicios de telesalud o regresando a ellos, respetaremos esa decisión, siempre que sea factible y clínicamente apropiado. Sin embargo, el reembolso de los servicios de telesalud también lo determinan las compañías de seguros y la ley aplicable, por lo que es posible que también debamos discutir este tema.',
	'gcf_covidRisk': 'Riesgos de Optar por Servicios Presenciales',
	'gcf_covidRiskUnderstand': 'Usted comprende que al venir a la oficina, está asumiendo el riesgo de exposición al coronavirus (u otros riesgos para la salud pública).',
	'gcf_covidRiskIncrease': 'Este riesgo puede aumentar si viaja en transporte público, taxi o servicio de viaje compartido.',
	'gcf_covidRiskExposure': 'Su Responsabilidad de Minimizar Su Exposición',
	'gcf_covidRiskExposureInperson': 'Para obtener servicios en persona, usted acepta tomar ciertas precauciones que ayudarán a mantener a todos (usted, su médico y nuestras familias, el resto del personal y otros pacientes) a salvo de la exposición, la enfermedad y la posible muerte. Si no cumple con estas medidas de seguridad, es posible que reprogramemos su visita para una fecha posterior. Ponga sus iniciales en cada uno para indicar que comprende y acepta estas acciones:',
	'gcf_covidSafeguard': 'Salvaguardias',
	'gcf_covidSafeguardAppointment': 'Solo asistirá a su cita en persona si usted y su hijo no tienen síntomas.',
	'gcf_covidTemperature': 'Le tomarán la temperatura o la temperatura de su hijo antes de acudir a cada cita. Si está elevado (99.5 Fahrenheit o más), o si tiene otros síntomas del coronavirus, acepta cancelar la cita y reprogramar. Si desea cancelar por este motivo, no le cobraremos ninguna tarifa.',
	'gcf_covidResident': 'Si un residente de su hogar da positivo por la infección, nos informará de inmediato y reprogramaremos la fecha.',
	'gcf_covidMask': 'Mientras recibe los servicios, ni el niño ni el profesional llevarán mascarilla ya que las expresiones faciales son parte importante del proceso.',
	'gcf_covidOrder': 'Podemos cambiar las precauciones anteriores si se publican órdenes o pautas locales, estatales o federales adicionales. Si eso sucede, hablaremos sobre los cambios necesarios.',
	'gcf_covidExposure': 'Nuestro Compromiso de Minimizar la Exposición',
	'gcf_covidExposureSteps': 'Hemos tomado medidas para reducir el riesgo de propagación del coronavirus dentro de la oficina y hemos publicado nuestros esfuerzos por correo electrónico y en la oficina. Háganos saber si tiene preguntas sobre estos esfuerzos.',
	'gcf_covidSick': 'Si Usted o Su Médico Están Enfermos',
	'gcf_covidSickSpread': 'Usted comprende que estamos comprometidos a mantener a todos a salvo de la propagación de este virus. Si se presenta a una cita y creemos que usted o su hijo tienen fiebre u otros síntomas, o creemos que usted o su hijo han estado expuestos, tendremos que pedirle que abandone la oficina inmediatamente. Podemos hacer un seguimiento con los servicios de reprogramación según corresponda.',
	'gcf_covidPositive': 'Si damos positivo por el coronavirus, se lo notificaremos para que pueda tomar las precauciones adecuadas.',
	'gcf_covidInformed': 'Consentimiento Informado',
	'gcf_covidInformedSuppliments': 'Este acuerdo complementa otros acuerdos comerciales/consentimiento informado general que hemos acordado.',
	'gcf_covidSignature': 'Su firma a continuación muestra que acepta estos términos y condiciones.',
	'gcf_financial': 'Responsabilidad Financiera y Reembolsos del Paciente',
	'gcf_financialResponsiblity': 'Responsabilidad Financiera del Paciente',
	'gcf_financialInsurance': 'Seguro:',
	'gcf_financialUnderstanding': 'Los tutores/pacientes son responsables de comprender sus políticas y beneficios de seguro. Si/cuando un paciente recibe servicios de Axis for Autism que terminan siendo negados debido a la falta de beneficios o en caso de que una agencia de financiación elimine a dicho paciente de su lista activa en medio de los servicios, el paciente o la(s) persona(s) legalmente responsable(s) para el paciente reembolsará a Axis for Autism todos los servicios prestados que no fueron reembolsados por la agencia financiadora.',
	'gcf_financialTreatment': 'Para establecer prioridades y objetivos de tratamiento realistas, es importante evaluar qué recursos tiene disponibles para pagar su tratamiento. Si tiene una póliza de seguro médico, generalmente brindará cierta cobertura para el tratamiento de salud mental/conductual. Axis for Autism completará formularios y le brindará toda la asistencia que podamos para ayudarlo a recibir los beneficios a los que tiene derecho; sin embargo, usted (no su compañía de seguros) es responsable del pago total de sus tarifas. Es importante que averigüe exactamente qué servicios de salud mental/conductual cubre su póliza de seguro.',
	'gcf_financialSection': 'Debe leer atentamente la sección del folleto de cobertura de su seguro que describe los servicios de salud mental/conductual. Si tiene preguntas sobre su cobertura, llame al administrador de su plan. Axis for Autism le proporcionará toda la información que podamos en función de nuestra experiencia y estará encantado de ayudarle a comprender la información que reciba de su compañía de seguros. Si es necesario aclarar una confusión, Axis for Autism estará dispuesto a llamar a la empresa en su nombre.',
	'gcf_financialRisingCost': 'Debido al aumento del costo de la atención médica, los beneficios del seguro se han vuelto cada vez más compleja. Es difícil determinar exactamente cuánta cobertura de salud mental/conductual está disponible. Los planes de atención administrada, como los HMO y los PPO, a menudo requieren autorización antes de proporcionar reembolsos por servicios de salud mental/conductual. Estos planes a menudo se limitan a enfoques de tratamiento a corto plazo diseñados para resolver problemas específicos que interfieren con el nivel habitual de funcionamiento de una persona. Puede ser necesario solicitar aprobación para realizar más terapia después de un cierto número de sesiones. Si bien se puede lograr mucho con la terapia a corto plazo, algunos pacientes sienten que necesitan más servicios una vez que finalicen los beneficios del seguro.',
	'gcf_financialCompanies': 'También debe tener en cuenta que la mayoría de las compañías de seguros exigen que usted autorice a su médico para que les proporcione un diagnóstico clínico. A veces, su médico deberá proporcionar información clínica adicional, como planes de tratamiento o resúmenes, o copias del registro completo (en casos excepcionales). Esta información pasará a formar parte de los archivos de la compañía de seguros y probablemente se almacenará en una computadora. Aunque todas las compañías de seguros afirman mantener dicha información confidencial, Axis for Autism no tiene control sobre lo que hacen con ella una vez que está en sus manos. En algunos casos, pueden compartir la información con un banco de datos médicos nacional. Su médico le proporcionará una copia de cualquier informe presentado si lo solicita.',
	'gcf_financialInformation': 'Una vez que tengamos toda la información sobre su cobertura de seguro, discutiremos qué podemos esperar lograr con los beneficios disponibles y qué sucederá si se agotan antes de que se completen todas las citas necesarias. Es importante recordar que usted siempre tiene derecho a pagar usted mismo nuestros servicios para evitar los problemas enumerados anteriormente, a menos que esté prohibido por el contrato.',
	'gcf_aba': 'Todos los coseguros y copagos vencen en el momento de los servicios de ABA y ya sea en el momento de la programación o el día antes de la cita inicial para el diagnóstico. Esta será una estimación del pago adeudado por su seguro, pero no es una garantía de pago completo. Es posible que reciba otra factura una vez que los reclamos hayan terminado de procesarse.',
	'gcf_updated': 'Los tutores/pacientes son responsables de proporcionar a Axis for Autism documentación actualizada del seguro cada enero y siempre que cambie la información del seguro.',
	'gcf_privatePay': 'Pago Privado',
	'gcf_privatepayAmount': 'Al pagar de forma privada, el monto total se debe pagar al momento de recibir los servicios de ABA (al momento de la programación o el día antes de la cita inicial para el diagnóstico).',
	'gcf_privatePayLate': 'Pagos Atrasados',
	'gcf_privatePayNotPaid': 'Si su cuenta no ha sido pagada durante más de 60 días y no se han acordado acuerdos de pago, Axis for Autism tiene la opción de utilizar medios legales para asegurar el pago. Esto puede implicar contratar una agencia de cobranza o acudir a un tribunal de reclamos menores. Si dicha acción legal fuera necesaria, sus costas se incluirán en la reclamación. En la mayoría de las situaciones de cobro, la única información que divulgaremos sobre el tratamiento de un paciente es su nombre, la naturaleza del servicio brindado y el monto adeudado.',
	'gcf_privatePayHardship': 'En circunstancias de dificultades financieras inusuales, Axis for Autism puede estar dispuesto a negociar un ajuste de tarifas o un plan de pago a plazos.',
	'gcf_privatePayGeneral': 'General',
	'gcf_privatePayCredit': 'Los pacientes deben pagar las tarifas del programa en efectivo, a través de cheque o tarjeta de crédito.',
	'gcf_professionalFee': 'Tarifas Profesionales',
	'gcf_professionalHourly': 'Axis for Autism cobrará una tarifa por hora por otros servicios profesionales que pueda necesitar, aunque desglosaremos el costo si su médico trabaja por períodos de menos de una hora. Otros servicios incluyen redacción de informes, conversaciones telefónicas de más de 30 minutos, asistencia a reuniones con otros profesionales que haya autorizado, preparación de registros o resúmenes de tratamientos y el tiempo dedicado a realizar cualquier otro servicio que solicite a su médico. Si se ve involucrado en procedimientos legales que requieren nuestra participación, se espera que pague por el tiempo profesional de su médico, incluso si otra parte lo llama a testificar. Debido a la dificultad de la participación legal, Axis for Autism cobra una tarifa por hora por la preparación y asistencia a los procedimientos legales. Comuníquese con la oficina al 602-888-8882 para conocer las tarifas actuales.',
	'gcf_patientRefund': 'Reembolsos para Pacientes',
	'gcf_patientRefundOverpay': 'Si se realiza un pago excesivo, los reembolsos se emitirán mediante cheque.',
	'gcf_patientRefundIndividual': 'Las solicitudes de reembolso individuales deben realizarse llamando a nuestra oficina al 602-888-8882 ext. 720. Las solicitudes se examinan caso por caso.',
	'gcf_patientRefundApproved': 'Una vez aprobados, los reembolsos se emiten mediante un cheque de la agencia Axis for Autism, para pagos realizados en efectivo o cheque o, para pagos con tarjeta de crédito, los reembolsos se acreditan en la tarjeta utilizada en el momento del pago.',
	'gcf_patientRefundReview': 'El proceso de revisión de reembolsos, incluida la emisión de reembolsos aprobados, generalmente se completa dentro de los 14 días posteriores a la solicitud.',
	'gcf_paymentJoint': 'Pago en Casos de Custodia Compartida',
	'gcf_paymentJointDispute': 'Nuestra oficina no se involucra en disputas domésticas ni cuestiones de custodia. Nuestra política es obtener el pago en el momento del servicio del tutor que trae al niño a la oficina. La persona con quien reside el paciente es responsable de cualquier saldo adeudado al recibir un estado de cuenta.',
	'gcf_guardian': 'Política de Quejas del Cliente/Tutor',
	'gcf_guardianDissatisfaction': 'Si una persona desea expresar su insatisfacción o presentar una inquietud con respecto a los servicios o el personal de Axis for Autism, se puede presentar una queja a través de un formulario de queja. Este formulario de queja se puede obtener a través del portal o solicitarse mediante una llamada telefónica, una carta o un correo electrónico (feedback(at)axisforautism.com). Este formulario se puede utilizar para expresar cualquier inquietud sobre los servicios de diagnóstico y tratamiento de Axis for Autism. Luego de una inquietud o queja, Axis for Autism completará los siguientes pasos:',
	'gcf_guardianDissatisfactionVerbally': 'Axis for Autism acusará recibo de la queja, ya sea verbalmente o por escrito, según la forma de presentación, dentro de los 5 días hábiles.',
	'gcf_guardianDissatisfactionSupervisor': 'Todas las inquietudes presentadas al equipo administrativo se resumirán y se enviarán a los supervisores departamentales correspondientes para su posterior revisión si no se logra una resolución en el momento inicial en que se presentó la inquietud. El supervisor puede programar una reunión telefónica con el denunciante para recopilar más información o avanzar en la investigación.',
	'gcf_guardianDissatisfactionReached': 'Cuando se haya llegado a una resolución, un socio de Axis for Autism comunicará un resumen del resultado de la inquietud presentada verbalmente o por escrito al reclamante.',
	'gcf_concerns': 'Las inquietudes relacionadas con abuso, negligencia, lesiones de pacientes o familiares/cuidadores de pacientes en la propiedad de Axis for Autism o durante sesiones en el hogar/comunidad o cualquier inquietud ética con el tratamiento se abordarán dentro de las 24 horas hábiles posteriores a la recepción de la inquietud.',
	'gcf_concernsResolve': 'Los socios de Axis for Autism intentarán resolver las inquietudes dentro de su ámbito una vez recibidas. Si la inquietud se resuelve a su satisfacción, se documentará la resolución y se cerrará la queja.',
	'gcf_finalDecision': 'Axis for Autism garantizará que los socios que toman la decisión final sobre las quejas no participen en un nivel previo de revisión o toma de decisiones.',
	'gcf_manner': 'Axis for Autism se compromete a responder a las inquietudes de los pacientes y tutores de manera oportuna y exhaustiva y tomará todas las medidas necesarias para satisfacer las necesidades del paciente, según corresponda, así como también cumplir con cualquier estándar de calidad, reglamentario u otros estándares reconocidos.',
	'gcf_grievanceProcedure': 'He leído y entiendo los procedimientos de quejas anteriores.',
	'gcf_signatureBelow': 'Su firma a continuación indica que ha leído la información contenida en este documento y acepta cumplir con sus términos.',
	'self': 'Ser',
	'spouse': 'Esposo/a',
	'child': 'Menor',
	'doctor_type': 'Seleccione el tipo de Doctor',
	'pediatrician': 'Pediatra',
	'developmental_pediatrician': 'Pediatra de Desarrollo',
	'psychiatrist': 'Psiquiatra',
	'counselor': 'Consejera',
	'occupational_therapist': 'Terapeuta Ocupacional',
	'speech_therapist': 'Logopeda (teraupeta de lengua)',
	'office4': 'Nombre de oficina',
	'roi_include_this_info': 'Incluir esto escuela sobre el consentimiento de divulgación de información.',
	'choose_file': 'Escoger un Archivo',

	//https://seacole.io/patients/appointment/intake/thankyou-intake-questionnaire-complete
	'ty_for_completing_intake': `¡Gracias por completar el cuestionario de admisión!`,
	'what_s_next': `¿Qué sigue?`,
	'review_and_sign_forms': 'Revise y firme los cuatro formularios de notificación y consentimiento:',
	'general_consent': 'Consentimiento general',
	'consent_for_clinical_services': 'Consentimiento para Servicios Clínicos',
	'notice_of_privacy_practices': 'Aviso de Prácticas de Privacidad',
	'notice_of_health_info_practices': 'Aviso de Prácticas de Información de Salud',
	'family_court_orders_note': 'Nota importante: Las órdenes del tribunal de familia pueden requerir que cada padre o tutor legal dé su consentimiento para este tipo de evaluación. Es posible que se le pida que proporcione información de contacto de otros padres o tutores legales que tengan autoridad legal para tomar decisiones sobre este paciente.',
	'contactUsMessage': 'Si tiene alguna pregunta o inquietud, antes de programar, comuníquese con nosotros al (602) 888-8882 o envíenos un correo electrónico a ',
	'begin_consents_message': `Para comenzar los consentimientos, seleccione el botón "Continuar" a continuación.`,
	'continue': 'Continuar',
	'signup_continue': 'Continuar',

	// https://seacole.io/patients/appointment/intake/thankyou-consents-complete

	'ty_for_completing_intake_process': '¡Gracias por completar el proceso de admisión!',
	'one_of_care_coordinator_will_contact': `Uno de nuestros coordinadores de atención al paciente se comunicará con usted dentro de los 7 días hábiles para programar una evaluación.
		Durante su cita, el proceso de prueba durará aproximadamente 45
		minutos, pero espere estar en la oficina por un total de 60 a 90
		minutos. El tiempo adicional permitirá realizar cuestionarios o
		discusiones posteriores a la evaluación y programar la próxima cita.
		Por favor, planifique en consecuencia.`,
	'care_coordinator_will_discuss': `El Coordinador de Atención al Paciente discutirá con usted cualquier
		información adicional requerida para la Evaluación durante el proceso
		de programación.`,
	'start_another_intake_question': '¿Necesita iniciar el proceso de admisión de otro paciente?',

	//https://seacole.io/patients/appointment/intake/release-of-information-form

	'roi_telephone': 'Teléfono',
	'name_of_patient': 'Nombre del Paciente',
	'patient_dob': 'Fecha de Nacimiento del Paciente',
	'ia_name_of_parent_guardian_auth': 'Si Corresponde, Nombre del Padre/Tutor que Proporciona la Autorización: ',
	'auth_to_release_info': `AUTORIZACIÓN PARA DIVULGAR/INTERCAMBIAR INFORMACIÓN MÉDICA PROTEGIDA (PHI) a Divulgar/intercambiar información médicaprotegida (PHI)`,
	'i_hereby_auth_axis': 'Por la presente autorizo a Axis for Autism, LLC a divulgar/intercambiar la siguiente información de salud confidencial (con el fin de la continuidad de la atención), relacionada con el cliente mencionado anteriormente por fax, teléfono, correo electrónico o en persona. Con este consentimiento, entiendo que esto significa que se pueden enviar documentos confidenciales a través de Internet y que Internet no siempre está completamente protegido y que usuarios no autorizados podrían obtener información médica protegida. Entiendo que este consentimiento puede retirarse en cualquier momento notificando a Axis for Autism, por escrito, a la siguiente dirección: 1645 E. Missouri Ave, Suite #310, Phoenix, AZ 85016.',
	'release_exchange_to': 'Divulgar/Intercambiar a:',
	'name_add_phone_fax_email': 'Nombre/Dirección/Teléfono/Fax/Correo Electrónico:',
	'name_contact_add_phone_fax_email': 'Nombre/Nombre de Contacto/Dirección/Teléfono/Fax/Correo Electrónico:',
	'office': 'Office',
	'roi_speech_therapist': 'Logopeda',
	'i_hereby': 'Por la presente',
	'authorize': ' Autorizo ',
	'do_not_authorize': ' No autorizo',
	'release_exchange_auth_quest':`la divulgación y/o intercambio, uso mutuo y/o
	divulgación
	de la información indicada anteriormente, para estos fines específicos, entre Axis for Autism y
	las agencias/profesionales enumerados anteriormente.`,
	'authorization_expires': 'Esta autorización vence',
	'on': ' En',
	'one_year_from_date': ' Un año a partir de la fecha de la firma',
	'upon_axis_receipt': ` Al recibir Axis for Autism una solicitud por escrito para revocar la autorización (en la dirección
		que aparece a continuación)`,
	'name_of_parent_guardian_signing': 'Nombre del Padre/Tutor Legal o Paciente Adulto que Firma',
	'date': 'Fecha',

	//https://seacole.io/patients/appointment/intake/consent/InsurancePreAuth
	'insurancePreauth': 'Tu seguro requiere preautorización',
	'ins_care_coordinator': 'Su proveedor de seguros requiere autorización previa. Un coordinador de atención al paciente se comunicará con usted dentro de las próximas 48 horas para analizar el proceso y obtener registros médicos. Si tiene alguna pregunta, no dude en llamar a nuestra oficina al 602-888-8882',
	
	'login': 'Acceso',
	'setup': '¡Vamos a configurarlo!',
	'contact_information_below': 'Por favor confirme o ingrese la información de contacto a continuación ',
	'completing_this_form': 'para la persona que completa este formulario.',
	'for_the_account_owner': 'Esta información es para el propietario de la cuenta. La información del paciente se recopilará en otra página.',
	'your_first_name': 'Su Nombre',
	'your_last_name': 'Tu Apellido',
	'your_email': 'Su dirección de correo electrónico',
	'your_phone': 'Tu número de teléfono',
	'your_preferred_language': 'Tu idioma preferido',
	'other_language': 'Ingrese otro idioma',
	'sign_up': 'Inscribirse',
	'something_not_right': 'Ups, algo no está bien!',
	'okay': 'BUENO',
	'invite_invalid': 'La invitación ha caducado o ya ha sido utilizada. Comuníquese con el administrador o llame a nuestra oficina al 602-888-8882.',
	'patient_exists': 'Ya existe un usuario con este correo electrónico o teléfono.',
	'check_phone_or_email': 'Por favor consultar teléfono o correo electrónico',
	'code_below': 'Ingrese el código a continuación',
	'code': 'Código',
	'invalid_code': 'Código invalido',
	'enter_correct_code': 'Asegúrese de utilizar el código correcto; de lo contrario, su cuenta se bloqueará.',
	'code_required': 'Se requiere código',
	'must_be_6_digits': 'Debe tener 6 dígitos',
	'verifcation_code': 'Tu Código De Verificación',
	'first_name_required': 'Su nombre es requerido',
	'last_name_required': 'Su apellido es requerido',
	'email_required': 'Su correo electrónico es requerido',
	'terms_required': 'Debes aceptar los términos de servicio',
	'invalid_email': 'Invalid Email',
	'i_agree': 'Estoy de acuerdo con la',
	'terms_of_service': 'Términos del Servicio',
	'email_or_phone': 'Correo electrónico',
	'account_inactive': 'Your account is inactive. Please contact administrator or call our office at 602-888-8882.',
	'email_or_phone_required': 'Se requiere correo electrónico',
	'phone_invalid': 'El teléfono no es válido',
	'loginError': 'Error de inicio de sesión',
	'loginErrorText': 'No pudimos encontrar una cuenta con el correo electrónico que ingresaste. Esto podría deberse a que:',
	'accountNotRegistererd': 'Su cuenta aún no está registrada.',
	'pleaseContactTeam': 'Si es nuevo en Axis for Autism, comuníquese con nuestro equipo al (602) 888-8882 para registrarse.',
	'typeOrDifferentEmail': 'Hay un error tipográfico o es posible que haya registrado una dirección de correo electrónico diferente.',
	'goPrevious': 'Vuelva a la pantalla anterior y verifique su dirección de correo electrónico.',
	'furtherAssistance': 'Si necesita más ayuda, comuníquese con nosotros al (602) 888-8882.',
	'preferred_language_required': 'Se requiere idioma preferido'
};
